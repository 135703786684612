import React from 'react'
import { API } from 'aws-amplify'
import { updateTag } from 'graphql/mutationsCustom'
import PropTypes from 'prop-types'
import { TextInput } from '../inputs/text-input'

const UpdateTag = async (data) => {
  const mutation = await API.graphql(
    {
      query: updateTag,
      authMode: 'AMAZON_COGNITO_USER_POOLS',
      variables: {
        input: data,
      },
    },
  )
  return mutation
}

const FormUpdateTag = ({ close, tag }) => {
  const [inputs, setInputs] = React.useState({
    title: null,
    description: null,
  })

  const handleChange = (event) => {
    const { name, value } = event.target
    setInputs((prev) => ({ ...prev, [name]: value }))
  }
  const handleClose = () => {
    setInputs({
      title: null,
      description: null,
    })
    close()
  }
  const handleSubmit = async (event) => {
    event.preventDefault()
    await UpdateTag({
      ...tag,
      title: inputs.title || tag.title,
      description: inputs.description || tag.description,
    })
    setInputs({
      title: null,
      description: null,
    })
    close()
  }
  return (

    <form onSubmit={handleSubmit}>
      <div className="flex flex-col justify-between w-full p-5 mx-auto h-96">
        <div>
          <h3 className="text-2xl font-semibold leading-loose tracking-wide text-left text-gray-700 uppercase border-b-2 border-gray-300">update tag</h3>
          <TextInput value={inputs.title || tag.title} label="title" name="title" handleChange={handleChange} placeholder="Edit tag title" />
          <TextInput value={inputs.description || tag.description} label="description" name="description" handleChange={handleChange} placeholder="Edit tag description" />
        </div>

        <div className="flex flex-row justify-around mt-5">
          <input type="submit" className="w-1/3 h-10 px-3 text-gray-100 capitalize bg-blue-500 rounded-md cursor-pointer" value="Update" />
          <button onClick={handleClose} type="button" className="w-1/3 h-10 px-3 text-gray-900 capitalize border border-gray-400 rounded-md "> Close </button>
        </div>

      </div>
    </form>
  )
}

FormUpdateTag.propTypes = {
  close: PropTypes.func.isRequired,
  tag: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
}

export default FormUpdateTag

export { FormUpdateTag }
