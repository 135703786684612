import React from 'react'
import ReactDOM from 'react-dom'
import 'assets/styles/index.css'
import { VideoStore } from 'store/videoContext'
import { UserStore } from 'store/user-context'
import { Amplify } from 'aws-amplify'
import Router from 'router'
import * as serviceWorker from './serviceWorker'
import 'assets/styles/amplify.css'
import credentials from './aws-exports'

Amplify.configure(credentials)
ReactDOM.render(
  <React.StrictMode>
    <UserStore>
      <VideoStore>
        <Router />
      </VideoStore>
    </UserStore>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
