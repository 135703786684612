import React from 'react'

export const dynamicClassNames = (classes) => classes
  .map((className) => {
    if (typeof className === 'object') {
      return Object.keys(className)
        .filter((classNameString) => className[classNameString])
        .join(' ')
    }

    return className
  })
  .join(' ')

export const Loading = ({
  isFull,
  children,
  isAnimated,
  className,
  width = 'w-32',
  height = 'h-32',
  color = 'text-primary',
}) => {
  const loadingClasses = dynamicClassNames([
    'flex items-center w-full',
    {
      'h-screen w-screen absolute top-0 left-0': isFull,
    },
    className,
  ])

  if (!isAnimated) return <>{children}</>

  return (
    <div className={loadingClasses}>
      <div className="mx-auto">
        <svg
          className={`animate-spin -ml-1 mr-3 ${color} ${width} ${height}`}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          />
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
      </div>
    </div>
  )
}

export default Loading
