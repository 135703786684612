import React from 'react'
import { API } from 'aws-amplify'
import { deleteCourse } from 'graphql/mutationsCustom'
import PropTypes from 'prop-types'

const DeleteCourse = async (data) => {
  const mutation = await API.graphql(
    {
      query: deleteCourse,
      authMode: 'AMAZON_COGNITO_USER_POOLS',
      variables: {
        input: data,
      },
    },
  )
  return mutation
}

const FormDeleteCourse = ({ close, course }) => {
  const handleSubmit = async (event) => {
    event.preventDefault()
    await DeleteCourse({ ...course })
    close()
  }
  return (

    <div className="w-full p-5 mx-auto  h-96">
      <h3 className="text-2xl font-semibold leading-loose tracking-wide text-left text-gray-700 uppercase border-b-2 border-gray-300">are you sure?</h3>
      <form onSubmit={handleSubmit}>
        <div className="flex flex-row justify-around mt-5">
          <button onClick={close} type="button" className="w-1/3 h-10 px-3 text-gray-100 capitalize bg-blue-500 rounded-md "> Close </button>
          <input type="submit" className="w-1/3 h-10 px-3 text-gray-100 capitalize bg-blue-500 rounded-md cursor-pointer" value="Delete" />
        </div>

      </form>
    </div>
  )
}

FormDeleteCourse.propTypes = {
  close: PropTypes.func.isRequired,
  course: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
}

export default FormDeleteCourse

export { FormDeleteCourse }
