/* eslint-disable */
export const getCourse = /* GraphQL */ `
  query GetCourse($id: ID!) {
    getCourse(id: $id) {
      id
      title
      description
      students
      owner
      executions {
        items {
          id
          title
          executor
          description
          identityID
          courseID
          createdAt
          updatedAt
        }
        nextToken
      }
      modelID
    }
  }
`;

/* eslint-disable */
export const getCourseLogs = /* GraphQL */ `
  query GetCourseLogs($id: ID!) {
    getCourse(id: $id) {
      id
      title
      description
      owner
      executions {
        items {
          id
          title
          executor
          description
          courseID
          identityID
          createdAt
          updatedAt
          tags {
            items {
              id
              title
              description
              executionID
              tags {
                items {
                  id
                  activityID
                  activity {
                    title
                    titleShort
                    category {
                      color
                      title
                      titleShort
                    }
                  }
                  tagID
                  start
                  end
                }
              }
              owner
            }
          }
        }
        nextToken
      }
      model {
        title
      }
      modelID
      createdAt
      updatedAt
    }
  }
`;

export const getModel = /* GraphQL */ `
query GetModel($id: ID!) {
  getModel(id: $id) {
    id
    title
    description
    categories {
      items {
        id
        title
        titleShort
        priority
        color
        modelID
        activities {
          items {
            id
            title
            titleShort
            priority
            categoryID
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
}
`

export const listModels = /* GraphQL */ `
  query ListModels(
    $filter: ModelModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;


export const listCourses = /* GraphQL */ `
  query ListCourses(
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        owner
        modelID
        students
        model {
          id
          title
          description
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;


export const getExecution = /* GraphQL */ `
  query GetExecution($id: ID!) {
    getExecution(id: $id) {
      id
      title
      executor
      description
      courseID
      students
      identityID
      course {
        id
        title
        description
        owner
        modelID
        students
        createdAt
        updatedAt
      }
      tags {
        items {
          id
          title
          description
          executionID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

export const getTag = /* GraphQL */ `
  query GetTag($id: ID!) {
    getTag(id: $id) {
      id
      title
      description
      executionID
      execution {
        id
        title
        executor
        description
        identityID
        courseID
        course {
          id
          title
          description
          owner
          modelID
          model {
            id
            title
            description
            categories {
              items {
                id
                title
                titleShort
                priority
                color
                modelID
                activities {
                  items {
                    id
                    title
                    titleShort
                    priority
                    categoryID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      tags {
        items {
          id
          activityID
          tagID
          start
          end
          activity {
            title
            titleShort
            category {
              color
              title
              titleShort
            }
          }
          tag {
            title
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      owner
      createdAt
      updatedAt
    }
  }
`;

export const getTagActivity = /* GraphQL */ `
  query GetTagActivity($id: ID!) {
    getTagActivity(id: $id) {
      id
      activityID
      tagID
      start
      end
      activity {
        id
        title
        titleShort
        priority
        categoryID
        category {
          id
          title
          titleShort
          priority
          color
          modelID
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;