import React from 'react'
import PropTypes from 'prop-types'

/**
 *
 * @param {*} children
 * @param {*} show
 */
const Modal = ({ children, show }) => (
  <div className={`bg-gray-700 z-10 bg-opacity-50 fixed w-full top-0 left-0 h-full overflow-auto ${!show && 'hidden'}`}>
    <div className="z-50 w-9/12 p-10 mx-auto my-32 bg-white rounded-lg opacity-100 ">
      {children}
    </div>
  </div>
)

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
  show: PropTypes.bool.isRequired,
}
export default Modal
export { Modal }
