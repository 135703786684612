import React, { useContext } from 'react';
import {
  BrowserRouter,
  Switch,
  Route,
} from 'react-router-dom';
import {
  AmplifyAuthenticator, AmplifySignUp, AmplifySignIn,
} from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import {
  Home, Course, Execution, Models, Courses, Model, Pome, Users, TagAnalysis,
} from 'views';
import { VideoContext } from 'store/videoContext';
import { UserContext } from 'store/user-context';

const Router = () => {
  const videoContext = useContext(VideoContext);
  const userContext = useContext(UserContext);
  const [authState, setAuthState] = React.useState();
  const [user, setUser] = React.useState();

  React.useEffect(() => onAuthUIStateChange((nextAuthState, authData) => {
    setAuthState(nextAuthState);
    if (authData) {
      const groups = authData.signInUserSession.idToken.payload['cognito:groups']
      setUser({ ...authData, groups, isAdmin: groups.find((a) => a === 'Admin') });
      userContext.setUser({ ...authData, groups, isAdmin: groups.find((a) => a === 'Admin') });
    }
    if (!authData) {
      setUser(undefined)
    }
  }), []);

  if (authState === AuthState.SignedIn && user) {
    return (
      <BrowserRouter>

        <div>
          <Switch>
            <Route path="/pome/:id">
              <Pome />
            </Route>
            <Route path="/executions/:id">
              <Execution user={user} />
            </Route>
            <Route path="/courses/:id" exact>
              <Course />
            </Route>
            <Route path="/courses/:id/tag-analysis">
              <TagAnalysis />
            </Route>
            <Route path="/courses">
              <Courses />
            </Route>
            <Route path="/models/:id">
              <Model />
            </Route>
            <Route path="/models">
              <Models />
            </Route>
            <Route path="/users">
              <Users />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </div>
        {videoContext.isLoading && (
          <div className="absolute bottom-0 w-full text-center text-gray-100 bg-blue-500">
            Loading Video
            {` ${((videoContext.loading === 1 ? 0.99 : videoContext.loading) * 100).toFixed(0)}%`}
          </div>
        )}
      </BrowserRouter>
    );
  }
  return (

    <AmplifyAuthenticator>
      <AmplifySignIn slot="sign-in" />
      <AmplifySignUp
        slot="sign-up"
        formFields={[
          { type: 'username', required: true },
          { type: 'email', required: true },
          {
            type: 'name',
            label: 'Name *',
            placeholder: 'Enter your name',
            required: true,
          },
          { type: 'password', required: true },
        ]}
      />
    </AmplifyAuthenticator>

  );
};

export default Router;
