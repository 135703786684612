import React, { useState, useEffect, useCallback } from 'react'
import { Auth, API } from 'aws-amplify'
import { DefaultLayout } from 'components/defaultLayout'
import PropTypes from 'prop-types'
import { listUsers } from 'utils/list-users'

let nextToken

async function listGroups(limit) {
  const apiName = 'AdminQueries'
  const path = '/listGroups'
  const myInit = {
    queryStringParameters: {
      limit,
      token: nextToken,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  }
  const { NextToken, ...rest } = await API.get(apiName, path, myInit)
  nextToken = NextToken
  return rest
}

async function addToGroup(user, group) {
  const apiName = 'AdminQueries'
  const path = '/addUserToGroup'
  const myInit = {
    body: {
      username: user,
      groupname: group,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  }
  return API.post(apiName, path, myInit)
}
async function removeFromGroup(user, group) {
  const apiName = 'AdminQueries'
  const path = '/removeUserFromGroup'
  const myInit = {
    body: {
      username: user,
      groupname: group,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  }
  return API.post(apiName, path, myInit)
}
async function disableUser(user) {
  const apiName = 'AdminQueries'
  const path = '/disableUser'
  const myInit = {
    body: {
      username: user,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  }
  return API.post(apiName, path, myInit)
}
async function enableUser(user) {
  const apiName = 'AdminQueries'
  const path = '/enableUser'
  const myInit = {
    body: {
      username: user,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  }
  return API.post(apiName, path, myInit)
}

async function listGroupsForUser(username) {
  const apiName = 'AdminQueries'
  const path = '/listGroupsForUser'
  const myInit = {
    queryStringParameters: {
      username,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  }
  const { ...rest } = await API.get(apiName, path, myInit)
  return rest
}

const AddUserToGroupForm = ({ users, groups }) => {
  const [inputs, setInputs] = useState({
    user: '',
    group: '',
  })
  const handleChange = (event) => {
    const { name, value } = event.target
    setInputs((prev) => ({ ...prev, [name]: value }))
  }
  const submit = (e) => {
    e.preventDefault()
    if (!inputs.user || !inputs.group) return
    addToGroup(inputs.user, inputs.group)
  }
  return (
    <form onSubmit={submit}>
      <label htmlFor="user">
        User
        <div>
          <select value={inputs.user} onChange={handleChange} name="user" id="user">
            <option value={null}> Select User</option>
            {users.map((i) => (<option key={i.sub} value={i.username}>{i.name}</option>))}
          </select>
        </div>
      </label>
      <label htmlFor="group">
        Role
        <div>
          <select value={inputs.group} onChange={handleChange} name="group" id="group">
            <option value={null}> Select Role</option>
            {groups.map((i) => (<option key={i} value={i}>{i}</option>))}
          </select>
        </div>
      </label>
      <input type="submit" value="Assign user role" />
    </form>
  )
}

AddUserToGroupForm.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape({
    sub: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  groups: PropTypes.arrayOf(PropTypes.string).isRequired,
}

const RemoveUserFromGroupForm = ({ users, groups }) => {
  const [inputs, setInputs] = useState({
    user: '',
    group: '',
  })
  const handleChange = (event) => {
    const { name, value } = event.target
    setInputs((prev) => ({ ...prev, [name]: value }))
  }
  const submit = (e) => {
    e.preventDefault()
    if (!inputs.user || !inputs.group) return
    removeFromGroup(inputs.user, inputs.group)
  }
  return (
    <form onSubmit={submit}>
      <label htmlFor="user">
        User
        <div>
          <select value={inputs.user} onChange={handleChange} name="user" id="user">
            <option value={null}> Select User</option>
            {users.map((i) => (<option key={i.sub} value={i.username}>{i.name}</option>))}
          </select>
        </div>
      </label>
      <label htmlFor="group">
        Role
        <div>
          <select value={inputs.group} onChange={handleChange} name="group" id="group">
            <option value={null}> Select Role</option>
            {groups.map((i) => (<option key={i} value={i}>{i}</option>))}
          </select>
        </div>
      </label>
      <input type="submit" value="Remove user role" />
    </form>
  )
}

RemoveUserFromGroupForm.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape({
    sub: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  groups: PropTypes.arrayOf(PropTypes.string).isRequired,
}

const Users = () => {
  const [users, setUsers] = useState([])
  const [groups, setGroups] = useState([])
  const [usersGroups, setUsersGroups] = useState({})
  const setUserGroup = useCallback(async (username) => {
    if (usersGroups[username]) return
    const { Groups } = await listGroupsForUser(username)
    setUsersGroups((prev) => ({ ...prev, [username]: Groups.filter((i) => (i.GroupName !== 'us-east-1_PMYsooGdJ_Google')).map(({ GroupName }) => GroupName) }))
  }, [usersGroups])
  useEffect(() => {
    const getUsersAndSet = async () => {
      const { Users: UsersFromCognito } = await listUsers(60)
      setUsers(UsersFromCognito.map((i) => [...i.Attributes, { Name: 'username', Value: i.Username }, { Name: 'enabled', Value: i.Enabled }]
        .reduce((prev, current) => ({ ...prev, [current.Name]: current.Value }), {})))
    }
    getUsersAndSet()
  }, [])
  useEffect(() => {
    const getGroupsAndSet = async () => {
      const { Groups } = await listGroups(60)
      setGroups(Groups.map(({ GroupName }) => GroupName))
    }
    getGroupsAndSet()
  }, [])
  useEffect(() => {
    users.forEach(({ username }) => setUserGroup(username))
  }, [setUserGroup, users])
  return (
    <DefaultLayout>
      <h1 className="text-xl tracking-widest capitalize">
        Users
      </h1>
      <AddUserToGroupForm users={users} groups={groups} />
      <div className="mt-5">
        <table className="w-full table-auto">
          <thead className="font-normal border-b border-gray-300">
            <tr className="text-left">
              <th className="px-4 py-2 font-semibold capitalize">Name</th>
              <th className="px-4 py-2 font-semibold capitalize">Enabled</th>
              <th className="px-4 py-2 font-semibold capitalize">Email</th>
              <th className="px-4 py-2 font-semibold capitalize">Roles</th>
              <th className="px-4 py-2 font-semibold capitalize"> </th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr className="transition-colors duration-500 hover:bg-gray-100 " key={user.sub}>
                <td className="px-4 py-2">
                  {user.name}
                </td>
                <td className="px-4 py-2">{user.enabled ? 'Yes' : 'No'}</td>
                <td className="px-4 py-2">{user.email}</td>
                <td className="px-4 py-2">
                  {usersGroups[user.username]?.reduce((prev, current) => `${prev !== '' ? `${prev},` : prev} ${current}`, '')}
                  {' '}
                </td>
                <td className="relative">
                  {!user.enabled && <button type="button" className="border" onClick={() => enableUser(user.username)}>Enable</button>}
                  {user.enabled && <button type="button" className="border" onClick={() => disableUser(user.username)}>Disable</button>}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <RemoveUserFromGroupForm users={users} groups={groups} />
    </DefaultLayout>
  )
}

export default Users
export { Users }
