import React, { useEffect, useState } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { DefaultLayout } from 'components'
import { getCourseLogs } from 'graphql/queriesCustom'
import { useParams, Link } from 'react-router-dom'
import { onCreateExecution, onUpdateExecution, onDeleteExecution } from 'graphql/subscriptionsCustom'
import { orderBy } from 'lodash'
import { CheckIcon, XIcon } from '@heroicons/react/solid'
import { dynamicClassNames } from 'components/loading/loading'
import { Levenshtein } from './metrics/levenshtein'
import { NeedlemanWunch } from './metrics/nedleman-wunsch'

const tryUnsubscribe = (subscription) => () => {
  try {
    return subscription.unsubscribe()
  } catch (error) {
    return ''
  }
}

const tagToString = (tag, mapper) => tag.tags.items.map(({ activityID }) => mapper[activityID]).join('')

const calculateLevenshteinDistance = (tag1, tag2) => {
  const t1 = tag1.tags.items.map(({ activityID }) => activityID)
  const t2 = tag2.tags.items.map(({ activityID }) => activityID)
  const uniqueIds = new Set([...t1, ...t2])
  const mapper = {}
  let index = 0
  uniqueIds.forEach((a) => {
    mapper[a] = String.fromCodePoint(50 + index)
    index += 1
  })
  const s1 = tagToString(tag1, mapper)
  const s2 = tagToString(tag2, mapper)

  return Math.round((1 - Levenshtein(s1, s2) / (s1.length + s2.length)) * 100)
}

// eslint-disable-next-line no-unused-vars
const TagLine = ({ tag1, tag2 }) => {
  const [distance, setDistance] = useState(null)
  const [levenshteinDistance, setLevenshteinDistance] = React.useState(null)

  // const [tagsInfo, setTagsInfo] = useState({})

  useEffect(
    () => {
      setLevenshteinDistance(calculateLevenshteinDistance(tag1, tag2))
    }, [tag1.id, tag2.id],

  )
  useEffect(() => {
    // const a = [tag1.tags.items, tag2.tags.items].flat().map(() => )
    setDistance(NeedlemanWunch(orderBy(tag1.tags.items, ['start', 'end']), orderBy(tag2.tags.items, ['start', 'end']), {
      gap: -1,
      match: 1,
      mismatch: -9,
    }))
  }, [tag1.id, tag2.id])

  if (!distance) return <></>

  return (
    <div className="grid overflow-hidden ">
      <div className="grid mb-8 text-center">
        <div className="relative mt-7">
          <div className="relative px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="max-w-4xl mx-auto bg-gray-50">
              <dl className="mt-3 bg-white rounded-lg shadow-lg sm:grid sm:grid-cols-2">

                <div className="flex flex-col p-6 text-center border-b border-gray-100 sm:border-0 sm:border-r">
                  <dt className="order-2 mt-2 text-lg font-medium leading-6 text-gray-500">Needleman Wunch score</dt>
                  <dd className="order-1 text-5xl font-extrabold text-indigo-600">{distance.score}</dd>
                </div>
                <div className="flex flex-col p-6 text-center border-b border-gray-100 sm:border-0 sm:border-r">
                  <dt className="order-2 mt-2 text-lg font-medium leading-6 text-gray-500">Levenshtein score</dt>
                  <dd className="order-1 text-5xl font-extrabold text-indigo-600">
                    {levenshteinDistance}
                    {' '}
                    %
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>

      </div>
      {distance.alignmentA.map((item, index) => {
        const isMatch = distance
          .alignmentA[index]?.activityID === distance.alignmentB[index]?.activityID
        return (
          <div key={`${item?.id + index}`} className="flex flex-row items-center justify-center w-full pb-8 ">
            <div className={dynamicClassNames(
              ['w-4/12 p-6 mx-8 text-left',
                { 'border rounded-2xl border-gray-300': Boolean(distance.alignmentA[index]) }],
            )}
            >
              {distance.alignmentA[index]?.activity.title || ''}
            </div>
            <div className="relative">
              {index !== distance.alignmentA.length - 1 ? <span className="absolute top-full h-80 left-1/2 -ml-px w-0.5 bg-gray-200" aria-hidden="true" /> : <span className="absolute top-full h-64 left-1/2 -ml-px w-0.5 bg-white" aria-hidden="true" />}
              <span
                className={dynamicClassNames([
                  'flex items-center justify-center w-8 h-8  rounded-full -left-4',
                  {
                    'bg-green-500': isMatch,
                    'bg-red-500': !isMatch,
                  },
                ])}
              >
                {isMatch ? <CheckIcon className="w-5 h-5 text-white" aria-hidden="true" /> : <XIcon className="w-5 h-5 text-white" aria-hidden="true" />}
              </span>
            </div>
            <div className={
              dynamicClassNames(
                ['w-4/12 p-6 mx-8 text-left',
                  { 'border rounded-2xl border-gray-300': Boolean(distance.alignmentB[index]) }],
              )
              }
            >
              {distance.alignmentB[index]?.activity.title || ''}
            </div>

          </div>

        )
      })}

    </div>
  )
}

const TagAnalysis = () => {
  const { id } = useParams()
  const [course, setCourse] = React.useState({})
  const [executions, setExecutions] = React.useState([])
  const [execution1, setExecution1] = React.useState('')
  const [execution2, setExecution2] = React.useState('')
  const [tag1, setTag1] = React.useState('')
  const [tag2, setTag2] = React.useState('')
  const [selectedTags, setSelectedTags] = React.useState({ tag1: null, tag2: null })
  const [filter, setFilter] = React.useState('')
  const [filteredExecutions, setFilteredExecutions] = React.useState([])

  const handleChange = (event) => {
    const { value } = event.target
    setFilter(value)
  }
  const handleSelector = (event) => {
    const { value } = event.target
    switch (event.target.name) {
      case 'execution1':
        setExecution1(value)
        break
      case 'execution2':
        setExecution2(value)
        break
      case 'tag1':
        setTag1(value)
        setSelectedTags((p) => ({
          ...p,
          tag1: executions.find((i) => i.id === execution1).tags.items.find((t) => t.id === value),
        }))
        break
      case 'tag2':
        setTag2(value)
        setSelectedTags((p) => ({
          ...p,
          tag2: executions.find((i) => i.id === execution2).tags.items.find((t) => t.id === value),
        }))
        break
      default:
        break
    }
  }
  const getCurrentCourse = React.useCallback(async () => {
    const { data } = await API.graphql(
      graphqlOperation(getCourseLogs, { id }),
    )
    setCourse(data.getCourse)
  }, [id])
  React.useEffect(() => {
    setFilteredExecutions(
      executions.filter(
        (execution) => execution.title.toLowerCase().search(filter.toLowerCase()) !== -1
        || execution.description.toLowerCase().search(filter.toLowerCase()) !== -1
        || execution.executor.toLowerCase().search(filter.toLowerCase()) !== -1,
      ),
    )
  }, [executions, filter])
  React.useEffect(() => {
    setExecutions(course?.executions?.items || [])
  }, [course])
  React.useEffect(() => {
    const subscription = API.graphql(
      graphqlOperation(onDeleteExecution),
    ).subscribe({
      next: (executionData) => setExecutions((prev) => {
        const execution = executionData.value.data.onDeleteExecution
        return prev.filter(({ id: pId }) => execution.id !== pId)
      }),
    })
    return tryUnsubscribe(subscription)
  }, [])
  React.useEffect(() => {
    getCurrentCourse()
  }, [getCurrentCourse])
  React.useEffect(() => {
    const subscription = API.graphql(
      graphqlOperation(onUpdateExecution),
    ).subscribe({
      next: (courseData) => setExecutions((prev) => {
        const execution = courseData.value.data.onUpdateExecution
        for (let index = 0; index < prev.length; index += 1) {
          const element = prev[index]
          if (element.id === execution.id) {
            const copy = [...prev]
            copy[index] = { ...copy[index], ...execution }
            return copy
          }
        }
        return prev
      }),
    })
    return tryUnsubscribe(subscription)
  }, [])
  React.useEffect(() => {
    const subscription = API.graphql(
      graphqlOperation(onCreateExecution),
    ).subscribe({
      next: (executionData) => setExecutions(

        (prev) => {
          if (executionData.value.data.onCreateExecution.courseID === id) {
            return [...prev, executionData.value.data.onCreateExecution]
          }
          return prev
        }
        ,
      ),
    })
    return tryUnsubscribe(subscription)
  }, [id])

  return (
    <DefaultLayout>
      <h1 className="text-xl tracking-widest capitalize">
        <Link to="/courses" className="hover:text-blue-600 hover:underline">
          My Courses
        </Link>
        {' > '}
        <Link to={`/courses/${course.id}`} className="hover:text-blue-600 hover:underline">
          {course?.title || 'Loading' }
        </Link>
        {' > Tag Analysis'}
      </h1>
      <div className="flex flex-row items-baseline justify-between mt-6">

        <input value={filter} onChange={handleChange} type="search" name="courses" id="search" placeholder="Type To Search" className="w-4/12 h-10 px-5 border border-gray-400 rounded-md" />
      </div>

      <div className="grid grid-cols-2 gap-5 mt-5">
        <select value={execution1} name="execution1" id="execution1" onChange={handleSelector} className="inline-block w-full h-10 px-5 border border-gray-400 rounded-md" placeholder="color">
          <option value={null}>Select execution 1</option>
          {
            executions.map((execution) => (
              <option value={execution.id} key={execution.id + 1}>
                {execution.title}
              </option>
            ))
          }
        </select>
        <select value={execution2} name="execution2" id="execution2" onChange={handleSelector} className="inline-block w-full h-10 px-5 border border-gray-400 rounded-md" placeholder="color">
          <option value={null}>Select execution 2</option>
          {
            executions.map((execution) => (
              <option value={execution.id} key={execution.id + 2}>
                {execution.title}
              </option>
            ))
          }
        </select>
      </div>
      <div className="grid grid-cols-2 gap-5 mt-5">
        <select value={tag1} name="tag1" id="tag1" onChange={handleSelector} className="inline-block w-full h-10 px-5 border border-gray-400 rounded-md" placeholder="color">
          <option value={null}>Select tag 1</option>
          {
            executions.find((e) => e.id === execution1)?.tags.items.map((tag) => (
              <option value={tag.id} key={tag.id + 1}>
                {tag.title}
              </option>
            ))
          }
        </select>
        <select value={tag2} name="tag2" id="tag2" onChange={handleSelector} className="inline-block w-full h-10 px-5 border border-gray-400 rounded-md" placeholder="color">
          <option value={null}>Select tag 2</option>
          {
            executions.find((e) => e.id === execution2)?.tags.items.map((tag) => (
              <option value={tag.id} key={tag.id + 1}>
                {tag.title}
              </option>
            ))
          }
        </select>
      </div>
      {/* begin table */}

      <div>
        {
        selectedTags.tag1 && selectedTags.tag2
          ? <TagLine tag1={selectedTags.tag1} tag2={selectedTags.tag2} />
          : null
      }
      </div>
      <div className="mt-5">
        <table className="w-full table-auto">
          <thead className="font-normal border-b border-gray-300">
            <tr className="text-left">
              <th className="px-4 py-2 font-semibold capitalize">title</th>
              <th className="px-4 py-2 font-semibold capitalize">executor</th>
              <th className="px-4 py-2 font-semibold capitalize">Created</th>
              <th className="px-4 py-2 font-semibold capitalize">Modified</th>
              <th className="px-4 py-2 font-semibold capitalize"> </th>
            </tr>
          </thead>
          <tbody>
            {filteredExecutions.map(({
              title, executor, updatedAt, createdAt, description, id: executionId,
            }) => (
              <tr className="transition-colors duration-500 hover:bg-gray-100 " key={executionId}>
                <td className="px-4 py-2">
                  <Link to={{ pathname: `/executions/${executionId}` }}>
                    <div className="text-lg font-light capitalize">
                      {title}
                    </div>
                  </Link>
                  <div className="text-sm font-light text-gray-600 capitalize">
                    {description}
                  </div>
                </td>
                <td className="px-4 py-2">{executor}</td>
                <td className="px-4 py-2">{createdAt.split('T')[0]}</td>
                <td className="px-4 py-2">{updatedAt.split('T')[0]}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* end table */}
    </DefaultLayout>
  )
}

export default TagAnalysis
