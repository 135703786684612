import React, { useContext } from 'react'
import { API, Auth, Storage } from 'aws-amplify'
import { createExecution } from 'graphql/mutationsCustom'
import PropTypes from 'prop-types'
import { VideoContext } from 'store/videoContext'
import { TextInput } from '../inputs/text-input'

const CreateExecution = async ({
  title, description, executor, courseID, identityID,
}) => {
  const mutation = await API.graphql(
    {
      query: createExecution,
      authMode: 'AMAZON_COGNITO_USER_POOLS',
      variables: {
        input: {
          title, description, executor, courseID, identityID,
        },
      },
    },
  )
  return mutation
}

const FormCreateExecution = ({ close, courseID }) => {
  const videoContext = useContext(VideoContext)
  const [inputs, setInputs] = React.useState({
    title: '',
    description: '',
    executor: '',
  })
  const [file, setFile] = React.useState(null)

  const onChange = (e) => {
    setFile(e.target.files[0])
  }
  const handleChange = (event) => {
    const { name, value } = event.target
    setInputs((prev) => ({ ...prev, [name]: value }))
  }
  const handleSubmit = async (event) => {
    event.preventDefault()
    const execution = await CreateExecution({
      ...inputs,
      courseID,
      identityID: (await Auth.currentUserCredentials()).identityId,
    })
    videoContext.setIsLoading(true)
    Storage.put(execution.data.createExecution.id, file, {
      contentType: 'video/mp4',
      level: 'protected',
      progressCallback(videoProgress) {
        videoContext.setLoading(videoProgress.loaded / videoProgress.total)
      },
    }).then(() => {
      videoContext.setLoading(0)
      videoContext.setIsLoading(false)
    }) // {key: "test.txt"}
    setFile(null)
    setInputs({
      title: '',
      description: '',
      executor: '',
    })
    close()
  }
  return (

    <form onSubmit={handleSubmit}>
      <div className="w-full p-5 mx-auto h-96">
        <h3 className="text-2xl font-semibold leading-loose tracking-wide text-left text-gray-700 uppercase border-b-2 border-gray-300">create new Execution</h3>
        <TextInput value={inputs.title} label="title" name="title" handleChange={handleChange} placeholder="insert execution title" />
        <TextInput value={inputs.description} label="description" name="description" handleChange={handleChange} placeholder="insert execution description" />
        <TextInput value={inputs.executor} label="executor" name="executor" handleChange={handleChange} placeholder="insert execution executor" />

        <label
          htmlFor="fileinput"
          className="flex flex-row justify-between mt-5"
        >
          <div className="inline-block capitalize">
            Video
          </div>
          <div className="inline-block w-7/12">
            <div className="items-baseline inline-block w-full h-10 px-5 text-gray-500 align-middle border border-gray-400 rounded-md">
              <div className="flex flex-col justify-center h-full">
                {file ? `video: ${file.name}` : 'Upload Video'}
              </div>
            </div>
          </div>
          <input
            type="file"
            accept="video/mp4"
            onChange={(evt) => onChange(evt)}
            className="z-30 hidden"
            id="fileinput"
          />
        </label>
        <div className="flex flex-row justify-around mt-5">
          <input type="submit" className="w-1/3 h-10 px-3 text-gray-100 capitalize bg-blue-500 rounded-md cursor-pointer" value="Create" />
          <button onClick={close} type="button" className="w-1/3 h-10 px-3 text-gray-900 capitalize border border-gray-400 rounded-md "> Close </button>
        </div>
      </div>
    </form>
  )
}

FormCreateExecution.propTypes = {
  close: PropTypes.func.isRequired,
  courseID: PropTypes.string.isRequired,
}

export default FormCreateExecution

export { FormCreateExecution }
