import React from 'react'
import { Header } from 'components/header'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { LeftDoubleArrow } from 'components/icons/leftDoubleArrow'
import { Menu } from 'components/icons/menu'
import { IfIsAdmin } from 'components/conditionaly-render/if-admin'
/**
 * @description this component launch children in de right space, display header and left menu
 */

const DefaultLayout = ({
  children, toggled, Title, hScreen,
}) => {
  const [toggle, setToggle] = React.useState(toggled)
  return (
    <div className="h-screen App">

      <div className={`flex flex-row  pt-12 ${hScreen ? 'h-screen' : 'min-h-screen'}`}>
        {/* Left Menu  */ }
        <div className={`transition-all border-r-2 border-gray-300 col-span-1 flex flex-col pt-4 ${toggle && 'w-12'} ${!toggle && 'w-1/6'}`}>
          {!toggle && (
          <Link to="/courses" className="h-8 pl-5 font-medium tracking-wider text-left capitalize hover:text-blue-600 hover:underline">
            Courses
          </Link>
          )}
          {!toggle && (
          <Link to="/models" className="h-8 pl-5 font-medium tracking-wider text-left capitalize hover:text-blue-600 hover:underline">
            Models
          </Link>
          )}
          <IfIsAdmin>
            {!toggle && (
            <Link to="/users" className="h-8 pl-5 font-medium tracking-wider text-left capitalize hover:text-blue-600 hover:underline">
              Users
            </Link>
            )}
          </IfIsAdmin>
          {!toggle && (
          <button onClick={() => setToggle(true)} type="button" className="flex flex-row h-8 pl-5 font-medium tracking-wider text-left capitalize hover:text-blue-600 hover:underline">
            <div className="self-center w-5 h-5">
              <LeftDoubleArrow />
            </div>
            <div className="self-center">
              Collapse
            </div>
          </button>
          )}
          {
            toggle && (
              <button onClick={() => setToggle(false)} type="button" className="flex flex-row hover:text-blue-600">
                <div className="self-center w-20 h-20 px-1">
                  <Menu />
                </div>
              </button>
            )
          }
        </div>
        {/* finish Left Menu */ }
        <div className="w-full p-3">
          {children}
        </div>
      </div>
      {/* Header  */ }
      <Header Title={Title} />
      {/* finish  Header  */ }
    </div>
  )
}
DefaultLayout.propTypes = {
  Title: PropTypes.element,
  toggled: PropTypes.bool,
  hScreen: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
}

DefaultLayout.defaultProps = {
  toggled: false,
  hScreen: false,
  Title: <></>,
}

export default DefaultLayout
export { DefaultLayout }
