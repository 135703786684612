/* eslint-disable import/prefer-default-export */
// Levenshtein(String, String) -> Integer
export function Levenshtein(a, b) {
  const n = a.length
  const m = b.length

  // matriz de cambios mínimos
  const d = []

  // si una de las dos está vacía, la distancia
  // es insertar todas las otras
  if (n === 0) { return m }
  if (m === 0) { return n }

  // inicializamos el peor caso (insertar todas)
  for (let i = 0; i <= n; i += 1) { (d[i] = [])[0] = i }
  for (let j = 0; j <= m; j += 1) { d[0][j] = j }

  // cada elemento de la matriz será la transición con menor coste
  for (let i = 1, I = 0; i <= n; i += 1, I += 1) {
    for (let j = 1, J = 0; j <= m; j += 1, J += 1) {
      if (b[J] === a[I]) d[i][j] = d[I][J]
      else d[i][j] = Math.min(d[I][j], d[i][J], d[I][J]) + 1
    }
  }

  // el menor número de operaciones
  return d[n][m]
}
