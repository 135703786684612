/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React from 'react'
import { API } from 'aws-amplify'
import { updateCourse } from 'graphql/mutationsCustom'
import PropTypes from 'prop-types'
import { listUsers } from 'utils/list-users'

const UpdateCourse = async ({
  id,
  title,
  description,
  owner,
  modelID,
  students,
}) => {
  const mutation = await API.graphql(
    {
      query: updateCourse,
      authMode: 'AMAZON_COGNITO_USER_POOLS',
      variables: {
        input: {
          id,
          title,
          description,
          owner,
          modelID,
          students,
        },
      },
    },
  )
  return mutation
}

const FormManageUsers = ({ close, course }) => {
  const [users, setUsers] = React.useState([])
  const [filter, setFilter] = React.useState('')

  const [selectedUsers, setSelectedUsers] = React.useState(course.students
    ? course.students.reduce((prev, current) => ({ ...prev, [current]: true }), {}) : {})
  const [inputs, setInputs] = React.useState({
    title: '',
    description: '',
  })

  const handleChange = (event) => {
    const { value } = event.target
    setFilter(value)
  }

  const handleClose = () => {
    setInputs({
      title: '',
      description: '',
    })
    close()
  }
  const handleSubmit = async (event) => {
    event.preventDefault()
    const removedUsers = new Set(Object.entries(selectedUsers).filter((entry) => !entry[1])
      .map((i) => i[0]))

    await UpdateCourse({
      ...course,
      title: inputs.title || course.title,
      description: inputs.description || course.description,
      students: [
        ...[course.students].flat(),
        ...Object.entries(selectedUsers).filter((entry) => entry[1]).map((entry) => entry[0]),
      ].filter(Boolean).filter((i) => !removedUsers.has(i)),
    })
    setInputs({
      title: '',
      description: '',
    })
    close()
  }
  React.useEffect(() => {
    (async () => setUsers((await listUsers(60))?.Users))()
  }, [])
  const selectedUsersSet = new Set(Object.entries(selectedUsers).filter((entry) => entry[1])
    .map((entry) => entry[0]))

  const userWithAttributes = users.map((user) => ({
    id: user.Username,
    name: user.Attributes.find(({ Name }) => Name === 'name').Value,
    email: user.Attributes.find(({ Name }) => Name === 'email').Value,
  }))

  const filteredUsers = filter !== '' ? userWithAttributes
    .filter((user) => user.name.toLowerCase().includes(filter.toLowerCase())
    || user.email.toLowerCase().includes(filter.toLowerCase())) : userWithAttributes

  return (

    <form onSubmit={handleSubmit}>
      <div className="flex flex-col justify-between w-full p-5 mx-auto min-h-96">
        <div>
          <h3 className="text-2xl font-semibold leading-loose tracking-wide text-left text-gray-700 uppercase border-b-2 border-gray-300">update course</h3>
          <h4 className="text-xl">Course Students</h4>
          <input
            value={filter}
            onChange={handleChange}
            type="search"
            name="courses"
            id="search"
            placeholder="Type To Search"
            className="w-4/12 h-10 px-5 border border-gray-400 rounded-md"
          />
          <div className="flex flex-col h-64 overflow-y-auto divide-y divide-gray-200">
            {
           filteredUsers.map((user) => (
             <div
               className="py-2 "
               role="button"
               onClick={() => {
                 setSelectedUsers((prev) => {
                   if (prev[user.id] === undefined) {
                     return {
                       ...prev,
                       [user.id]: !course?.students?.find((name) => name === user.id),
                     }
                   }
                   if (!prev[user.id]) {
                     return { ...prev, [user.id]: true }
                   }
                   return { ...prev, [user.id]: false }
                 })
               }}
               key={user.id}
             >
               <input
                 id={user.id}
                 checked={
                   selectedUsersSet.has(user.id)
                }
                 aria-describedby="comments-description"
                 name="comments"
                 type="checkbox"
                 className="w-4 h-4 mr-2 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
               />
               <div className="inline-flex">
                 {user.name}
                 <br />
                 {user.email}
               </div>
             </div>
           ))
         }
          </div>

          <div className="flex flex-row justify-around mt-5">
            <input type="submit" className="w-1/3 h-10 px-3 text-gray-100 capitalize bg-blue-500 rounded-md cursor-pointer" value="Update" />
            <button onClick={handleClose} type="button" className="w-1/3 h-10 px-3 text-gray-900 capitalize border border-gray-400 rounded-md "> Close </button>
          </div>
        </div>
      </div>
    </form>
  )
}

FormManageUsers.propTypes = {
  close: PropTypes.func.isRequired,
  course: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
}

export default FormManageUsers

export { FormManageUsers }
