import React from 'react'
import { API } from 'aws-amplify'
import { createActivity } from 'graphql/mutationsCustom'
import PropTypes from 'prop-types'
import { TextInput } from '../inputs/text-input'

const CreateActivity = async ({
  title,
  titleShort,
  priority,
  categoryID,
}) => API.graphql({
  query: createActivity,
  authMode: 'AMAZON_COGNITO_USER_POOLS',
  variables: {
    input: {
      title,
      titleShort,
      priority,
      categoryID,
    },
  },

})

const FormCreateActivity = ({ close, categoryID, priority = 0 }) => {
  const [inputs, setInputs] = React.useState({
    title: '',
    titleShort: '',
    priority: '',
  })

  const handleChange = (event) => {
    const { name, value } = event.target
    setInputs((prev) => ({ ...prev, [name]: value }))
  }
  const handleSubmit = async (event) => {
    event.preventDefault()
    await CreateActivity({ ...inputs, priority: inputs.priority || priority, categoryID })
    setInputs({
      title: '',
      titleShort: '',
      priority: '',
    })
    close()
  }
  return (

    <form onSubmit={handleSubmit}>
      <div className="flex flex-col justify-between w-full p-5 mx-auto h-96">
        <div>
          <h3 className="text-2xl font-semibold leading-loose tracking-wide text-left text-gray-700 uppercase border-b-2 border-gray-300">create new Activity</h3>
          <TextInput label="title" name="title" handleChange={handleChange} placeholder="insert activity title" />
          <TextInput maxLength={35} label="short title" name="titleShort" handleChange={handleChange} placeholder="insert activity short title" />

          <label htmlFor="title" className="flex flex-row justify-between mt-5">
            <div className="inline-block capitalize">
              priority
            </div>
            <div className="inline-block w-7/12">
              <input value={inputs.priority || priority} name="priority" type="number" onChange={handleChange} className="inline-block w-full h-10 px-5 border border-gray-400 rounded-md" placeholder="priority" />
            </div>
          </label>
        </div>
        <div className="flex flex-row justify-around mt-5">
          <input type="submit" className="w-1/3 h-10 px-3 text-gray-100 capitalize bg-blue-500 rounded-md cursor-pointer" value="Create" />
          <button onClick={close} type="button" className="w-1/3 h-10 px-3 text-gray-900 capitalize border border-gray-400 rounded-md "> Close </button>
        </div>
      </div>
    </form>
  )
}

FormCreateActivity.propTypes = {
  close: PropTypes.func.isRequired,
  categoryID: PropTypes.string.isRequired,
  priority: PropTypes.number.isRequired,
}

export default FormCreateActivity

export { FormCreateActivity }
