import React from 'react'
import { Link } from 'react-router-dom'
import { AmplifySignOut } from '@aws-amplify/ui-react'
import PropTypes from 'prop-types'

const Header = ({ Title }) => (
  <div className="absolute top-0 left-0 flex items-center justify-between w-screen h-12 bg-gray-100 border-b-2 border-gray-300">
    <Link to="/" className="px-10 hover:underline">
      Pome Suite
    </Link>
    { Title }
    <nav>
      <AmplifySignOut>Sign Out</AmplifySignOut>
    </nav>
  </div>
)

Header.propTypes = {
  Title: PropTypes.element,
}

Header.defaultProps = {
  Title: <></>,
}
export default Header
export { Header }
