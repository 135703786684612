import React from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { DefaultLayout, Modal } from 'components'
import { } from 'components/forms'
import { listModels } from 'graphql/queriesCustom'
import { FormCreateModel } from 'components/forms/model/createModel'
import { FormDeleteModel } from 'components/forms/model/deleteModel'
import { FormUpdateModel } from 'components/forms/model/updateModel'
import { onCreateModel, onUpdateModel, onDeleteModel } from 'graphql/subscriptionsCustom'
import { Link } from 'react-router-dom'
import { TreDotsModal } from 'components/modals/treDotsModal'
import { IfIsAdmin } from 'components/conditionaly-render/if-admin'

const tryUnsubscribe = (subscription) => () => {
  try {
    return subscription.unsubscribe()
  } catch (error) {
    return ''
  }
}

const Home = () => {
  const [show, toggle] = React.useState(false)
  const [showUpdate, toggleUpdate] = React.useState(false)
  const [showDelete, toggleDelete] = React.useState(false)
  const [models, setModels] = React.useState([])
  const [filteredModels, setFilteredModels] = React.useState([])
  const [filter, setFilter] = React.useState('')
  const [selectedModel, setSelectedModel] = React.useState({})
  const getModels = React.useCallback(async () => {
    const { data } = await API.graphql(
      {
        query: listModels,
      },
    )
    setModels(data.listModels?.items || [])
  }, [])
  const handleChange = (event) => {
    const { value } = event.target
    setFilter(value)
  }

  React.useEffect(() => {
    getModels()
    const subscription = API.graphql(
      graphqlOperation(onCreateModel),
    ).subscribe({
      next: (modelData) => setModels((prev) => [...prev, modelData.value.data.onCreateModel]),
    })
    return tryUnsubscribe(subscription)
  }, [getModels])
  React.useEffect(() => {
    const subscription = API.graphql(
      graphqlOperation(onUpdateModel),
    ).subscribe({
      next: (modelData) => setModels((prev) => {
        const model = modelData.value.data.onUpdateModel
        for (let index = 0; index < prev.length; index += 1) {
          const element = prev[index]
          if (element.id === model.id) {
            const copy = [...prev]
            copy[index] = { ...copy[index], ...model }
            return copy
          }
        }
        return prev
      }),
    })
    return tryUnsubscribe(subscription)
  }, [])
  React.useEffect(() => {
    const subscription = API.graphql(
      graphqlOperation(onDeleteModel),
    ).subscribe({
      next: (modelData) => setModels((prev) => {
        const model = modelData.value.data.onDeleteModel
        return prev.filter(({ id }) => model.id !== id)
      }),
    })
    return tryUnsubscribe(subscription)
  }, [])

  React.useEffect(() => {
    setFilteredModels(
      models.filter((model) => model.title.toLowerCase().search(filter.toLowerCase()) !== -1
      || model.description.toLowerCase().search(filter.toLowerCase()) !== -1),
    )
  }, [models, filter])
  return (
    <DefaultLayout>
      <Modal show={show}>
        <FormCreateModel close={() => toggle(false)} />
      </Modal>
      <Modal show={showUpdate}>
        <div>
          {selectedModel.id
          && <FormUpdateModel model={{ ...selectedModel }} close={() => { toggleUpdate(false) }} />}
        </div>
      </Modal>
      <Modal show={showDelete}>
        <div>
          {selectedModel.id
          && <FormDeleteModel model={{ ...selectedModel }} close={() => { toggleDelete(false) }} />}
        </div>
      </Modal>
      <h1 className="text-xl tracking-widest capitalize">
        My Models
      </h1>
      <div className="flex flex-row items-baseline justify-between mt-6">
        <IfIsAdmin>
          <button onClick={() => toggle(true)} type="button" className="w-2/12 h-10 px-3 text-gray-100 capitalize bg-blue-500 rounded-md ">
            + new model
          </button>
        </IfIsAdmin>
        <input value={filter} onChange={handleChange} type="search" name="models" id="search" placeholder="Type To Search" className="w-4/12 h-10 px-5 border border-gray-400 rounded-md" />
      </div>
      {/* begin table */}
      <div className="mt-5">
        <table className="w-full table-auto">
          <thead className="font-normal border-b border-gray-300">
            <tr className="text-left">
              <th className="px-4 py-2 font-semibold capitalize">Title</th>
              <th className="px-4 py-2 font-semibold capitalize">Created</th>
              <th className="px-4 py-2 font-semibold capitalize">Modified</th>
              <th className="px-4 py-2 font-semibold capitalize"> </th>
            </tr>
          </thead>
          <tbody>
            {filteredModels.map(({
              title, updatedAt, createdAt, description, id,
            }) => (
              <tr className="transition-colors duration-500 hover:bg-gray-100 " key={id}>
                <td className="px-4 py-2">
                  <Link to={{ pathname: `/models/${id}` }}>
                    <div className="text-lg font-light capitalize">
                      {title}
                    </div>
                  </Link>
                  <div className="text-sm font-light text-gray-600 capitalize">
                    {description}
                  </div>
                </td>
                <td className="px-4 py-2">{createdAt.split('T')[0]}</td>
                <td className="px-4 py-2">{updatedAt.split('T')[0]}</td>
                <td className="relative">
                  <TreDotsModal
                    onEdit={() => setSelectedModel({ title, description, id }, toggleUpdate(true))}
                    onDelete={() => setSelectedModel({ id }, toggleDelete(true))}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* end table */}
    </DefaultLayout>
  )
}

export default Home
