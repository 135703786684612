/* eslint-disable no-plusplus */
const generateEmptyMatrix = (sequenceA, sequenceB, gapValue) => {
  const row = new Array(sequenceB.length + 1).fill(0);
  const matrix = [];
  sequenceA.forEach(() => matrix.push([...row]));
  matrix.push([...row]);
  for (let index = 0; index < matrix[0].length; index++) {
    matrix[0][index] = index * gapValue;
  }
  for (let index = 0; index < matrix.length; index++) {
    matrix[index][0] = index * gapValue;
  }

  return matrix;
};

const similarity = (A, B, options) => {
  if (A.activityID === B.activityID) return options.match;
  return options.mismatch;
};

const movePos = ([x, y]) => [x + 1, y - 1];

const isValidPos = (xLength, yLength) => ([x, y]) => {
  if (x <= 0 || y <= 0) return false;
  if (x > xLength || y > yLength) return false;
  return true;
};

const indexToIterate = (x, y) => {
  const pairs = [];
  for (let index = 0; index < y + x + 1; index++) {
    let step = [0, index];
    pairs.push([...step]);
    for (let index2 = 0; index2 < x + y + 1; index2++) {
      step = movePos(step);
      pairs.push([...step]);
    }
  }

  return pairs.filter(isValidPos(x, y));
};

const align = ({
  matrix, sequenceA, sequenceB, options,
}) => {
  let alignmentA = [];
  let alignmentB = [];
  let y = sequenceA.length;
  let x = sequenceB.length;
  const score = matrix[y][x];

  while (x >= 0 && y >= 0 && x + y !== 0) {
    // eslint-disable-next-line no-shadow
    const score = matrix[y][x];
    const scoreDiag = x !== 0 && y !== 0 ? matrix[y - 1][x - 1] : null;
    const scoreUp = y !== 0 ? matrix[y - 1][x] : null;
    const scoreLeft = x !== 0 ? matrix[y][x - 1] : null;
    const elementA = sequenceA[y - 1];
    const elementB = sequenceB[x - 1];
    if (
      scoreDiag !== null
      && score === scoreDiag + similarity(elementA, elementB, options)
    ) {
      alignmentA = [sequenceA[y - 1], ...alignmentA];
      alignmentB = [sequenceB[x - 1], ...alignmentB];
      y--;
      x--;
    } else if (scoreLeft !== null && score === scoreLeft + options.gap) {
      alignmentA = [null, ...alignmentA];
      alignmentB = [elementB, ...alignmentB];
      x--;
    } else if (scoreUp !== null && score === scoreUp + options.gap) {
      alignmentA = [elementA, ...alignmentA];
      alignmentB = [null, ...alignmentB];
      y--;
    } else {
      throw new Error('something went wrong');
    }
  }
  return {
    alignmentB,
    alignmentA,
    score,
  };
};

// eslint-disable-next-line import/prefer-default-export
export const NeedlemanWunch = (
  sequenceA,
  sequenceB,
  options = { gap: -1, match: 1, mismatch: -2 },
) => {
  const matrix = generateEmptyMatrix(sequenceA, sequenceB, options.gap);
  const coordinates = indexToIterate(sequenceB.length, sequenceA.length);
  coordinates.forEach(([x, y]) => {
    matrix[y][x] = Math.max(
      matrix[y - 1][x - 1]
        + similarity(sequenceA[y - 1], sequenceB[x - 1], options),
      matrix[y][x - 1] + options.gap,
      matrix[y - 1][x] + options.gap,
    );
  });
  return align({
    sequenceA,
    sequenceB,
    matrix,
    options,
  });
};
