export const onCreateCategory = /* GraphQL */ `
  subscription OnCreateCategory {
    onCreateCategory {
      id
      title
      titleShort
      priority
      color
      modelID
      activities {
        items {
          id
          title
          titleShort
          priority
          categoryID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`
export const onUpdateCategory = /* GraphQL */ `
  subscription OnUpdateCategory {
    onUpdateCategory {
      id
      title
      titleShort
      priority
      color
      modelID
      activities {
        items {
          id
          title
          titleShort
          priority
          categoryID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`

export const onDeleteCategory = /* GraphQL */ `
  subscription OnDeleteCategory {
    onDeleteCategory {
      id
      title
      titleShort
      priority
      color
      modelID
      activities {
        items {
          id
          title
          titleShort
          priority
          categoryID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`

export const onCreateActivity = /* GraphQL */ `
  subscription OnCreateActivity {
    onCreateActivity {
      id
      title
      titleShort
      priority
      categoryID
      createdAt
      updatedAt
    }
  }
`
export const onUpdateActivity = /* GraphQL */ `
  subscription OnUpdateActivity {
    onUpdateActivity {
      id
      title
      titleShort
      priority
      categoryID
      createdAt
      updatedAt
    }
  }
`
export const onDeleteActivity = /* GraphQL */ `
  subscription OnDeleteActivity {
    onDeleteActivity {
      id
      title
      titleShort
      priority
      categoryID
      createdAt
      updatedAt
    }
  }
`

export const onCreateModel = /* GraphQL */ `
  subscription OnCreateModel {
    onCreateModel {
      id
      title
      description
      createdAt
      updatedAt
    }
  }
`
export const onUpdateModel = /* GraphQL */ `
  subscription OnUpdateModel {
    onUpdateModel {
      id
      title
      description
      createdAt
      updatedAt
    }
  }
`
export const onDeleteModel = /* GraphQL */ `
  subscription OnDeleteModel {
    onDeleteModel {
      id
      title
      description
      createdAt
      updatedAt
    }
  }
`
export const onCreateTag = /* GraphQL */ `
  subscription OnCreateTag($owner: String) {
    onCreateTag(owner: $owner) {
      id
      title
      description
      executionID
      owner
      createdAt
      updatedAt
    }
  }
`

export const onCreateTagAdmin = /* GraphQL */ `
  subscription OnCreateTag {
    onCreateTag {
      id
      title
      description
      executionID
      owner
      createdAt
      updatedAt
    }
  }
`

export const onUpdateTag = /* GraphQL */ `
  subscription OnUpdateTag($owner: String) {
    onUpdateTag(owner: $owner) {
      id
      title
      description
      executionID
      owner
      createdAt
      updatedAt
    }
  }
`
export const onUpdateTagAdmin = /* GraphQL */ `
  subscription OnUpdateTag {
    onUpdateTag {
      id
      title
      description
      executionID
      owner
      createdAt
      updatedAt
    }
  }
`
export const onDeleteTag = /* GraphQL */ `
  subscription OnDeleteTag($owner: String) {
    onDeleteTag(owner: $owner) {
      id
      title
      description
      executionID
      owner
      createdAt
      updatedAt
    }
  }
`
export const onDeleteTagAdmin = /* GraphQL */ `
  subscription OnDeleteTag {
    onDeleteTag {
      id
      title
      description
      executionID
      owner
      createdAt
      updatedAt
    }
  }
`

export const onCreateExecution = /* GraphQL */ `
  subscription OnCreateExecution {
    onCreateExecution {
      id
      title
      executor
      students
      description
      courseID
      createdAt
      updatedAt
    }
  }
`
export const onUpdateExecution = /* GraphQL */ `
  subscription OnUpdateExecution {
    onUpdateExecution {
      id
      title
      students
      executor
      description
      courseID
      createdAt
      updatedAt
    }
  }
`
export const onDeleteExecution = /* GraphQL */ `
  subscription OnDeleteExecution {
    onDeleteExecution {
      id
      title
      students
      executor
      description
      courseID
      createdAt
      updatedAt
    }
  }
`
export const onCreateCourse = /* GraphQL */ `
  subscription OnCreateCourse {
    onCreateCourse {
      id
      title
      description
      owner
      modelID
      students
      model {
        id
        title
        description
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`
export const onUpdateCourse = /* GraphQL */ `
  subscription OnUpdateCourse {
    onUpdateCourse {
      id
      title
      description
      owner
      students
      modelID
      model {
        id
        title
        description
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`
export const onDeleteCourse = /* GraphQL */ `
  subscription OnDeleteCourse {
    onDeleteCourse {
      id
      title
      description
      owner
      students
      modelID
      createdAt
      updatedAt
    }
  }
`

export const onCreateTagActivity = /* GraphQL */ `
  subscription OnCreateTagActivity {
    onCreateTagActivity {
      id
      activityID
      tagID
      start
      end
      activity {
        id
        title
        titleShort
        priority
        categoryID
        category {
          id
          title
          titleShort
          priority
          color
          modelID
          activitiesOrder
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`
