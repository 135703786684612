/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { DefaultLayout, Modal } from 'components'
import { getExecution, getTag } from 'graphql/queriesCustom'
import { useParams, Link } from 'react-router-dom'
import { TreDotsModal } from 'components/modals/treDotsModal'
import { FormCreateTag } from 'components/forms/tag/createTag'
import { FormDeleteTag } from 'components/forms/tag/deleteTag'
import { FormUpdateTag } from 'components/forms/tag/updateTag'
import {
  onCreateTag, onDeleteTag, onUpdateTag, onCreateTagAdmin, onDeleteTagAdmin, onUpdateTagAdmin,
} from 'graphql/subscriptionsCustom'
import { FormManageUsers } from 'components/forms/execution/manage-users'
import { IfIsAdmin } from 'components/conditionaly-render/if-admin'

const tagToCSV = (tag, tagActivities) => [
  ...tagActivities.map((tagAct) => [
    tagAct.activity.title,
    tagAct.activity.titleShort,
    tagAct.activity.category.title,
    tagAct.activity.category.titleShort,
    tag?.title,
    tag?.execution?.title,
    tag?.execution?.course?.title,
    tagAct.start,
    tagAct.end,
    tagAct.id,
    tagAct.tagID,
    tagAct.activityID,
    tag?.execution?.courseID,
    tag?.execution?.id,
  ]),
]

const getTagActivitiesCSV = async (id) => {
  const { data } = await API.graphql(
    graphqlOperation(getTag, { id }),
  )
  return tagToCSV(data.getTag, data.getTag.tags.items)
}

const tryUnsubscribe = (subscription) => () => {
  try {
    return subscription.unsubscribe()
  } catch (error) {
    return ''
  }
}
const Execution = ({ user }) => {
  const { id } = useParams()
  const [execution, setExecution] = React.useState({})
  const [show, toggle] = React.useState(false)
  const [showUpdate, toggleUpdate] = React.useState(false)
  const [showDelete, toggleDelete] = React.useState(false)
  const [selectedTag, setSelectedTag] = React.useState({})
  const [tags, setTags] = React.useState([])
  const [filter, setFilter] = React.useState('')
  const [filteredTags, setFilteredTags] = React.useState([])
  const [selectedTags, setSelectedTags] = React.useState({})
  const [showManageUsers, toggleManageUsers] = React.useState(false)

  const handleChange = (event) => {
    const { value } = event.target
    setFilter(value)
  }
  const handleChangeTag = (event) => {
    const { checked, name } = event.target
    setSelectedTags((prev) => ({ ...prev, [name]: checked }))
  }
  const getCurrentExecution = React.useCallback(async () => {
    const { data } = await API.graphql(
      graphqlOperation(getExecution, { id }),
    )
    setExecution(data.getExecution)
  }, [id])

  const onClickButton = async (title) => {
    const tagsToExport = await Promise.all(
      Object.entries(selectedTags).map(async (item) => {
        const [key, value] = item
        if (!value) return []
        return getTagActivitiesCSV(key)
      }),
    )
    const rows = [['activity title', 'activity title short', 'category title',
      'category title short', 'tag title', 'execution title', 'course title',
      'start (sec)', 'end (sec)', 'ID', 'tagID', 'activityID', 'courseID', 'executionID'], ...tagsToExport.flat()]
    const csvContent = `data:text/csv;charset=utf-8,${
      rows.map((e) => e.join(',')).join('\n')}`
    const encodedUri = encodeURI(csvContent)
    const link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute('download', `${title}.csv`)
    document.body.appendChild(link) // Required for FF

    link.click()
  }

  React.useEffect(() => {
    setTags(execution?.tags?.items || [])
  }, [execution])
  React.useEffect(() => {
    const subscription = API.graphql(
      user?.isAdmin
        ? graphqlOperation(onCreateTagAdmin, { owner: user.username })
        : graphqlOperation(onCreateTag, { owner: user.username }),
    ).subscribe({
      next: (tagData) => {
        setTags(
          (prev) => [...prev,
            tagData.value.data.onCreateTag].filter(({ executionID }) => executionID === id),
        )
      },
    })
    return tryUnsubscribe(subscription)
  }, [id])
  React.useEffect(() => {
    const subscription = API.graphql(
      user?.isAdmin
        ? graphqlOperation(onUpdateTagAdmin, { owner: user.username })
        : graphqlOperation(onUpdateTag, { owner: user.username }),
    ).subscribe({
      next: (tagData) => setTags(
        (prev) => prev.map((tagItem) => (tagItem.id === tagData.value.data.onUpdateTag.id
          ? tagData.value.data.onUpdateTag : tagItem)),
      ),
    })
    return tryUnsubscribe(subscription)
  }, [id])
  React.useEffect(() => {
    const subscription = API.graphql(
      user?.isAdmin
        ? graphqlOperation(onDeleteTagAdmin, { owner: user.username })
        : graphqlOperation(onDeleteTag, { owner: user.username }),
    ).subscribe({
      next: (tagData) => setTags(
        (prev) => prev.filter((tagItem) => tagItem.id !== tagData.value.data.onDeleteTag.id),
      ),
    })
    return tryUnsubscribe(subscription)
  }, [id])
  React.useEffect(() => {
    getCurrentExecution()
  }, [getCurrentExecution])
  React.useEffect(() => {
    setFilteredTags(
      tags.filter(
        (tag) => tag.title.toLowerCase().search(filter.toLowerCase()) !== -1
        || tag.description.toLowerCase().search(filter.toLowerCase()) !== -1,
      ),
    )
  }, [tags, filter])

  return (
    <DefaultLayout>
      <Modal show={showManageUsers}>
        <div>
          {execution.id
          && (
          <FormManageUsers
            students={execution.course.students}
            execution={{ ...execution }}
            close={() => { toggleManageUsers(false) }}
          />
          )}
        </div>
      </Modal>
      <Modal show={show}>
        <FormCreateTag close={() => toggle(false)} executionID={id} />
      </Modal>
      <Modal show={showDelete}>
        <div>
          {selectedTag.id
          && <FormDeleteTag tag={{ ...selectedTag }} close={() => { toggleDelete(false) }} />}
        </div>
      </Modal>
      <Modal show={showUpdate}>
        <div>
          {selectedTag.id
          && <FormUpdateTag tag={{ ...selectedTag }} close={() => { toggleUpdate(false) }} />}
        </div>
      </Modal>
      <h1 className="text-xl tracking-widest capitalize">
        <Link to="/courses" className="hover:text-blue-600 hover:underline">
          My Courses
        </Link>
        {' > '}

        <Link to={`/courses/${execution?.courseID}`} className="hover:text-blue-600 hover:underline">
          {execution?.course?.title || 'Loading' }
        </Link>
        {' > '}
        {execution?.title || 'Loading' }
      </h1>
      <div className="flex flex-row items-baseline justify-between mt-6">
        <button onClick={() => toggle(true)} type="button" className="w-2/12 h-10 px-3 text-gray-100 capitalize bg-blue-500 rounded-md ">
          + New Tag
        </button>
        <IfIsAdmin>
          <button
            onClick={() => toggleManageUsers(true)}
            type="button"
            className="w-2/12 h-10 px-3 text-gray-100 capitalize bg-blue-500 rounded-md "
          >
            Manage Users
          </button>
        </IfIsAdmin>
        <button onClick={() => onClickButton(execution.title)} type="button" className="w-2/12 h-10 px-3 text-gray-100 capitalize bg-blue-500 rounded-md ">
          Export Logs
        </button>
        <input value={filter} onChange={handleChange} type="search" name="courses" id="search" placeholder="Type To Search" className="w-4/12 h-10 px-5 border border-gray-400 rounded-md" />
      </div>
      {/* begin table */}
      <div className="mt-5">
        <table className="w-full table-auto">
          <thead className="font-normal border-b border-gray-300">
            <tr className="text-left">
              <th className="px-4 py-2 font-semibold capitalize" />
              <th className="px-4 py-2 font-semibold capitalize">title</th>
              <th className="px-4 py-2 font-semibold capitalize">Created</th>
              <th className="px-4 py-2 font-semibold capitalize">Modified</th>
              <th className="px-4 py-2 font-semibold capitalize"> </th>
            </tr>
          </thead>
          <tbody>
            {filteredTags.map(({
              title, updatedAt, createdAt, description, id: tagId,
            }) => (
              <tr className="transition-colors duration-500 hover:bg-gray-100 " key={tagId}>
                <td>
                  <input type="checkbox" name={tagId} checked={selectedTag[tagId]} onChange={handleChangeTag} />
                </td>
                <td className="px-4 py-2">
                  <Link to={{ pathname: `/pome/${tagId}` }}>
                    <div className="text-lg font-light capitalize">
                      {title}
                    </div>
                  </Link>
                  <div className="text-sm font-light text-gray-600 capitalize">
                    {description}
                  </div>
                </td>
                <td className="px-4 py-2">{createdAt.split('T')[0]}</td>
                <td className="px-4 py-2">{updatedAt.split('T')[0]}</td>
                <td className="relative">
                  <TreDotsModal
                    onEdit={() => setSelectedTag({
                      id: tagId,
                      description,
                      title,
                    }, toggleUpdate(true))}
                    onDelete={() => setSelectedTag({ id: tagId }, toggleDelete(true))}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* end table */}
    </DefaultLayout>
  )
}

export default Execution
