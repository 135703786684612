import React, { useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { VerticalDots } from 'components/icons/verticalDots'
import { UserContext } from 'store/user-context';

const TreDotsModal = ({ onEdit, onDelete }) => {
  const { currentUser } = useContext(UserContext)
  const [toggle, setToggle] = React.useState(false)
  const [globalClick, setGlobalClick] = React.useState(0)
  const [event, setEvent] = React.useState(() => {})
  const handleEvent = () => {
    setGlobalClick((p) => 1 + p)
  }
  const handleClick = (tog) => {
    if (!tog) {
      window.addEventListener('click', handleEvent)
      setToggle((p) => !p)
      setEvent(() => () => {
        window.removeEventListener('click', handleEvent)
      })
    } else {
      setGlobalClick(4)
    }
  }
  useEffect(() => {
    if (globalClick >= 2) {
      setToggle(false)
      setGlobalClick(0)
      event()
    }
  }, [event, globalClick])
  if (!currentUser || !currentUser.isAdmin) return null
  return (
    <div className="relative">
      <button type="button" onClick={() => { handleClick(toggle) }}>
        <VerticalDots />
      </button>
      <div className={`absolute bg-white rounded-lg border-gray-900 border w-32 -ml-32 -mt-10 ${!toggle && 'hidden'}`}>
        <ul className="">
          <li>
            <button onClick={onEdit} type="button" className="w-full px-2 my-1 text-left hover:bg-gray-100 hover:border-gray-600">
              edit
            </button>
          </li>
          <li>
            <button onClick={onDelete} type="button" className="w-full px-2 my-1 text-left hover:bg-gray-100 hover:border-gray-600">
              delete
            </button>
          </li>
        </ul>
      </div>
    </div>
  )
}

TreDotsModal.propTypes = {
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

export default TreDotsModal
export { TreDotsModal }
