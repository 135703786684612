import React from 'react'
import { API } from 'aws-amplify'
import { createTag } from 'graphql/mutationsCustom'
import PropTypes from 'prop-types'
import { TextInput } from '../inputs/text-input'

const CreateTag = async ({
  title, description, executionID,
}) => {
  const mutation = await API.graphql(
    {
      query: createTag,
      authMode: 'AMAZON_COGNITO_USER_POOLS',
      variables: {
        input: {
          title, description, executionID,
        },
      },
    },
  )
  return mutation
}

const FormCreateTag = ({ close, executionID }) => {
  const [inputs, setInputs] = React.useState({
    title: '',
    description: '',
  })

  const handleChange = (event) => {
    const { name, value } = event.target
    setInputs((prev) => ({ ...prev, [name]: value }))
  }
  const handleClose = () => {
    setInputs({
      title: '',
      description: '',
    })
    close()
  }
  const handleSubmit = async (event) => {
    event.preventDefault()
    await CreateTag({ ...inputs, executionID })
    setInputs({
      title: '',
      description: '',
    })
    close()
  }
  return (

    <form onSubmit={handleSubmit}>
      <div className="flex flex-col justify-between w-full p-5 mx-auto h-96">
        <div>
          <h3 className="text-2xl font-semibold leading-loose tracking-wide text-left text-gray-700 uppercase border-b-2 border-gray-300">create new Tag</h3>
          <TextInput value={inputs.title} label="title" name="title" handleChange={handleChange} placeholder="Insert tag title" />
          <TextInput value={inputs.description} label="description" name="description" handleChange={handleChange} placeholder="Edit tag description" />
        </div>

        <div className="flex flex-row justify-around mt-5">
          <input type="submit" className="w-1/3 h-10 px-3 text-gray-100 capitalize bg-blue-500 rounded-md cursor-pointer" value="Create" />
          <button onClick={handleClose} type="button" className="w-1/3 h-10 px-3 text-gray-900 capitalize border border-gray-400 rounded-md "> Close </button>
        </div>
      </div>
    </form>
  )
}

FormCreateTag.propTypes = {
  close: PropTypes.func.isRequired,
  executionID: PropTypes.string.isRequired,
}

export default FormCreateTag

export { FormCreateTag }
