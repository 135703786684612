import React from 'react'
import { API } from 'aws-amplify'
import { updateModel } from 'graphql/mutationsCustom'
import PropTypes from 'prop-types'
import { TextInput } from '../inputs/text-input'

const UpdateModel = async (data) => {
  const mutation = await API.graphql(
    {
      query: updateModel,
      authMode: 'AMAZON_COGNITO_USER_POOLS',
      variables: {
        input: data,
      },
    },
  )
  return mutation
}

const FormUpdateModel = ({ close, model }) => {
  const [inputs, setInputs] = React.useState({
    title: null,
    description: null,
  })

  const handleChange = (event) => {
    const { name, value } = event.target
    setInputs((prev) => ({ ...prev, [name]: value }))
  }
  const handleClose = () => {
    setInputs({
      title: null,
      description: null,
    })
    close()
  }
  const handleSubmit = async (event) => {
    event.preventDefault()
    await UpdateModel({
      ...model,
      title: inputs.title || model.title,
      description: inputs.description || model.description,
    })
    setInputs({
      title: null,
      description: null,
    })
    close()
  }
  return (

    <div className="w-full p-5 mx-auto h-96">
      <h3 className="text-2xl font-semibold leading-loose tracking-wide text-left text-gray-700 uppercase border-b-2 border-gray-300">update model</h3>
      <form onSubmit={handleSubmit}>
        <TextInput value={inputs.title || model.title} label="title" name="title" handleChange={handleChange} placeholder="insert model title" />
        <TextInput value={inputs.description || model.description} label="description" name="description" handleChange={handleChange} placeholder="insert course description" />
        <div className="flex flex-row justify-around mt-5">
          <input type="submit" className="w-1/3 h-10 px-3 text-gray-100 capitalize bg-blue-500 rounded-md cursor-pointer" value="Update" />
          <button onClick={handleClose} type="button" className="w-1/3 h-10 px-3 text-gray-900 capitalize border border-gray-400 rounded-md "> Close </button>
        </div>

      </form>
    </div>
  )
}

FormUpdateModel.propTypes = {
  close: PropTypes.func.isRequired,
  model: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
}

export default FormUpdateModel

export { FormUpdateModel }
