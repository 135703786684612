import React, { createContext } from 'react'
import PropTypes from 'prop-types'

const UserContext = createContext({})

const UserStore = ({ children }) => {
  const [currentUser, setUser] = React.useState(null)
  return (
    <UserContext.Provider value={
    {
      currentUser, setUser,
    }
  }
    >
      {children}
    </UserContext.Provider>
  )
}
UserStore.propTypes = { children: PropTypes.node.isRequired }

export { UserContext, UserStore }
