/* eslint-disable import/prefer-default-export */
import React from 'react'

export const TextInput = ({
  value, handleChange, name, label, placeholder, maxLength,
}) => (
  <label htmlFor={name} className="flex flex-row justify-between mt-5">
    <div className="inline-block capitalize">
      {label}
    </div>
    <div className="inline-block w-7/12">
      <input maxLength={maxLength} value={value} name={name} type="text" onChange={handleChange} className="inline-block w-full h-10 px-5 border border-gray-400 rounded-md" placeholder={placeholder} />
    </div>
  </label>
)
