import React from 'react'
import { API } from 'aws-amplify'
import { createCategory } from 'graphql/mutationsCustom'
import PropTypes from 'prop-types'
import { TextInput } from '../inputs/text-input'

const CreateCategory = async ({
  title, titleShort, color, modelID, priority,
}) => {
  const mutation = await API.graphql(
    {
      query: createCategory,
      authMode: 'AMAZON_COGNITO_USER_POOLS',
      variables: {
        input: {
          title, titleShort, color, priority, modelID,
        },
      },
    },
  )
  return mutation
}

const FormCreateCategory = ({ close, modelID }) => {
  const [inputs, setInputs] = React.useState({
    title: '',
    titleShort: '',
    color: '',
    priority: '',
  })

  const handleChange = (event) => {
    const { name, value } = event.target
    setInputs((prev) => ({ ...prev, [name]: value }))
  }
  const handleSubmit = async (event) => {
    event.preventDefault()
    await CreateCategory({ ...inputs, modelID })
    setInputs({
      title: '',
      titleShort: '',
      color: '',
      priority: '',
    })
    close()
  }
  return (

    <form onSubmit={handleSubmit}>
      <div className="flex flex-col justify-between w-full p-5 mx-auto h-96">
        <div>
          <h3 className="text-2xl font-semibold leading-loose tracking-wide text-left text-gray-700 uppercase border-b-2 border-gray-300">create new Category</h3>
          <TextInput label="title" name="title" handleChange={handleChange} placeholder="insert category title" />
          <TextInput label="short title" name="titleShort" handleChange={handleChange} placeholder="insert short title" maxLength={35} />

          <label htmlFor="title" className="flex flex-row justify-between mt-5">
            <div className="inline-block capitalize">
              priority
            </div>
            <div className="inline-block w-7/12">
              <input value={inputs.priority} name="priority" type="number" onChange={handleChange} className="inline-block w-full h-10 px-5 border border-gray-400 rounded-md" placeholder="priority" />
            </div>
          </label>
          <label htmlFor="color" className="flex flex-row justify-between mt-5">
            <div className="inline-block capitalize">
              color
            </div>
            <div className="inline-block w-7/12">
              <select name="color" id="color" onChange={handleChange} className="inline-block w-full h-10 px-5 border border-gray-400 rounded-md" placeholder="color">
                <option value={null}>Select color</option>
                <option value="gray">gray</option>
                <option value="red">red</option>
                <option value="orange">orange</option>
                <option value="yellow">yellow</option>
                <option value="green">green</option>
                <option value="teal">teal</option>
                <option value="blue">blue</option>
                <option value="indigo">indigo</option>
                <option value="purple">purple</option>
                <option value="pink">pink</option>
              </select>
            </div>
          </label>
        </div>
        <div className="flex flex-row justify-around mt-5">
          <input type="submit" className="w-1/3 h-10 px-3 text-gray-100 capitalize bg-blue-500 rounded-md cursor-pointer" value="Create" />
          <button onClick={close} type="button" className="w-1/3 h-10 px-3 text-gray-900 capitalize border border-gray-400 rounded-md "> Close </button>
        </div>
      </div>
    </form>
  )
}

FormCreateCategory.propTypes = {
  close: PropTypes.func.isRequired,
  modelID: PropTypes.string.isRequired,
}

export default FormCreateCategory

export { FormCreateCategory }
