/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react'
import {
  API, graphqlOperation, Storage,
} from 'aws-amplify'
import { DefaultLayout } from 'components'
import { getTag } from 'graphql/queriesCustom'
import { useParams, Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  createTagActivity,
  deleteTagActivity,
  updateTagActivity,
} from 'graphql/mutationsCustom'
import { CSVLink } from 'react-csv'
import { Disclosure, Transition } from '@headlessui/react'
import {
  ChevronUpIcon, PlusCircleIcon, TrashIcon, PlayIcon, StopIcon,
} from '@heroicons/react/solid'
import { Loading } from 'components/loading/loading'

const formatNumber = (seconds) => {
  const quotient = Math.floor(seconds / 60)
  const remainder = seconds % 60
  return `${`00${quotient}`.slice(-2)}:${`00${remainder}`.slice(-2)}`
}

const CreateTagActivity = async ({
  start, end, activityID, tagID,
}) => {
  const { data } = await API.graphql({
    query: createTagActivity,
    authMode: 'AMAZON_COGNITO_USER_POOLS',
    variables: {
      input: {
        start,
        end,
        activityID,
        tagID,
      },
    },
  })
  return data.createTagActivity
}
const UpdateTagActivity = async ({
  id, start, end, activityID, tagID,
}) => {
  const { data } = await API.graphql({
    query: updateTagActivity,
    authMode: 'AMAZON_COGNITO_USER_POOLS',
    variables: {
      input: {
        id,
        start,
        end,
        activityID,
        tagID,
      },
    },
  })
  return data.createTagActivity
}
const DeleteTagActivity = async (id) => {
  const { data } = await API.graphql({
    query: deleteTagActivity,
    authMode: 'AMAZON_COGNITO_USER_POOLS',
    variables: {
      input: {
        id,
      },
    },
  })
  return data.deleteTagActivity
}

const ListCategories = ({ category, handleClick, isPrediction }) => (
  <>
    <Disclosure as="div" className="mt-2">
      {({ open }) => (
        <>
          <Disclosure.Button key={category.id} className={`flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-${isPrediction ? 'sky' : category.color}-900 bg-${isPrediction ? 'sky' : category.color}-100 rounded-lg hover:bg-${isPrediction ? 'sky' : category.color}-200 focus:outline-none focus-visible:ring focus-visible:ring-${isPrediction ? 'sky' : category.color}-500 focus-visible:ring-opacity-75 ${isPrediction ? 'border-sky-500 border-2' : ''}`}>
            <span>{!isPrediction ? category.titleShort : 'Prediction'}</span>
            <ChevronUpIcon
              className={`${
                (open) ? 'transform rotate-180' : ''
              } w-5 h-5 text-${isPrediction ? 'sky' : category.color}-500`}
            />
          </Disclosure.Button>
          <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
            <Transition
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <div
                className={`w-full flex flex-col gap-3 border-${
                  isPrediction ? 'sky' : category.color
                }-700  transform transition-all ease-linear duration-500 `}
              >

                {category.activities?.items?.map((act) => (
                  <button
                    onClick={() => handleClick(act, category)}
                    key={act.id}
                    type="button"
                    aria-details="asdas"
                    className={`pr-1 pb-2 text-left block w-full  border-b border-${isPrediction ? 'sky' : category.color}-500`}
                  >
                    <div className="flex flex-row items-center">
                      <div className="w-5 h-5 mr-2">
                        <PlusCircleIcon className={`h-5 w-5 text-${isPrediction ? 'sky' : category.color}-500 `} />
                      </div>
                      <div className="text-base text-gray-900 ">
                        <div className="truncate">
                          {act.titleShort}
                        </div>
                        <div className="text-xs text-gray-700">{act.title}</div>
                      </div>
                    </div>
                  </button>
                ))}
              </div>
            </Transition>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>

  </>
)

ListCategories.propTypes = {
  category: PropTypes.shape({
    id: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    titleShort: PropTypes.string.isRequired,
    activities: PropTypes.shape({
      items: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          titleShort: PropTypes.string,
        }),
      ),
    }),
    title: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  handleClick: PropTypes.func.isRequired,
}

ListCategories.defaultProps = {

}

const PomeLog = () => {
  const { id } = useParams()
  const [tag, setTag] = React.useState({})
  const [tagActivities, setTagActivities] = React.useState([])
  const [sortedTagActivities, setSortedTadActivities] = React.useState([])
  const [url, setUrl] = React.useState('')
  const [categories, setCategories] = React.useState([])
  const [modelActivities, setModelActivities] = React.useState([])
  const [prediction, setPrediction] = React.useState({})
  const videoRef = React.useRef(null)

  const getCurrentTag = React.useCallback(async () => {
    const { data } = await API.graphql(graphqlOperation(getTag, { id }))
    setTag(data.getTag)
    setTagActivities(data.getTag.tags.items)
    const getCategories = data.getTag.execution.course.model.categories.items
    setCategories(
      getCategories
        .sort((a, b) => a.priority - b.priority)
        .map((cat) => ({
          ...cat,
          activities: {
            items: cat.activities?.items.sort(
              (a, b) => a.priority - b.priority,
            ),
          },
        })),
    )
  }, [id])
  React.useEffect(() => {
    setModelActivities(categories.map((c) => c.activities.items).flat())
  }, [categories])
  React.useEffect(() => {
    setSortedTadActivities(
      tagActivities.sort(
        (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
      ),
    )
  }, [tagActivities])
  React.useEffect(() => {
    getCurrentTag()
  }, [getCurrentTag])

  React.useEffect(() => {
    if (!tag?.execution?.id) return
    Storage.get(tag?.execution?.id, {
      level: 'protected',
      identityId: tag?.execution?.identityID ?? 'us-east-1:be71d444-485e-45cf-92f7-44caaad0a375',
    }).then((result) => {
      setUrl(result)
    })
  }, [tag])

  const clickActivity = async (activity, category) => {
    const data = await CreateTagActivity({
      tagID: id,
      activityID: activity.id,
      start: Math.floor(videoRef.current.currentTime),
      end: Math.ceil(videoRef.current.currentTime),
    })
    if (!data) return
    setTagActivities((prev) => [
      {
        ...data,
        activity: {
          ...activity,
          category: { ...category },
        },
      },
      ...prev,
    ])
    // TODO que hacer cuando es la ultima
    const nextActivityIndex = (modelActivities.findIndex((i) => i.id === activity.id) + 1)
      % modelActivities.length
    const nextActivity = modelActivities[nextActivityIndex]
    setPrediction({
      ...categories.find((c) => nextActivity.categoryID === c.id),
      activities: {
        items: [nextActivity],
      },
    })
  }

  if (!url) {
    return (
      <DefaultLayout toggled>
        <Loading isFull isAnimated color="text-blue-500" />
      </DefaultLayout>
    )
  }
  const handleDeleteTagActivity = async (tagActivityID) => {
    setTagActivities((prev) => prev.filter((i) => i.id !== tagActivityID))
    DeleteTagActivity(tagActivityID)
  }
  const handleUpdateTagActivity = async (activity, start) => {
    const newActivity = {
      ...activity,
      start: start ? Math.floor(videoRef.current.currentTime) : activity.start,
      end: !start ? Math.ceil(videoRef.current.currentTime) : activity.end,
    }
    if (newActivity.start > newActivity.end) {
      if (start) {
        newActivity.end = newActivity.start
      } else {
        newActivity.start = newActivity.end
      }
    }

    setTagActivities((prev) => prev.map((i) => {
      if (i.id !== activity.id) return i
      return newActivity
    }))
    UpdateTagActivity(newActivity)
  }
  return (
    <DefaultLayout
      toggled
      hScreen
      Title={(
        <h1 className="ml-8 text-xl tracking-widest capitalize">
          <Link to="/courses" className="hover:text-blue-600 hover:underline">
            My Courses
          </Link>
          {' > '}

          <Link
            to={`/courses/${tag?.execution?.courseID}`}
            className="hover:text-blue-600 hover:underline"
          >
            {tag?.execution?.course?.title || 'Loading'}
          </Link>
          {' > '}
          <Link
            to={`/executions/${tag?.execution?.id}`}
            className="hover:text-blue-600 hover:underline"
          >
            {tag?.execution?.title || 'Loading'}
          </Link>
          {' > '}
          {tag?.title || 'Loading'}
        </h1>
    )}
    >
      {/* begin table */}
      <div className="grid h-full grid-cols-6 gap-2 mr-5">
        <div className="h-full col-span-2 px-2 overflow-auto">

          <CSVLink
            filename={`${tag?.title}.csv`}
            data={[
              [
                'activity title',
                'activity title short',
                'category title',
                'category title short',
                'tag title',
                'execution title',
                'course title',
                'start (sec)',
                'end (sec)',
                'ID',
                'tagID',
                'activityID',
                'courseID',
                'executionID',
              ],
              ...sortedTagActivities.map((tagAct) => [
                tagAct.activity.title,
                tagAct.activity.titleShort,
                tagAct.activity.category.title,
                tagAct.activity.category.titleShort,
                tag?.title,
                tag?.execution?.title,
                tag?.execution?.course?.title,
                tagAct.start,
                tagAct.end,
                tagAct.id,
                tagAct.tagID,
                tagAct.activityID,
                tag?.execution?.courseID,
                tag?.execution?.id,
              ]),
            ]}
            className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 mt-2 w-full shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-blue-500"
          >
            Download Data
          </CSVLink>

          {
             categories.length > 0 ? (
               <ListCategories
                 isPrediction
                 handleClick={clickActivity}
                 key={prediction.id + prediction}
                 category={prediction.id ? prediction : categories[0]}
               />
             ) : null
          }

          {categories.map((i) => (
            <ListCategories
              handleClick={clickActivity}
              key={i.id}
              category={i}
            />
          ))}
        </div>
        <div className="flex flex-col justify-between w-full h-full col-span-4 pb-7">
          <div className="mx-auto ">
            <video
              ref={videoRef}
              controls
              className="object-contain w-full max-w-4xl h-10/12 object"
            >
              <source src={url} />
            </video>
          </div>
          <div className="w-full mr-auto overflow-visible overflow-y-scroll border-2 max-h-64">
            {sortedTagActivities.map((i) => (
              <div
                key={i.id}
                className="flex flex-row items-center justify-between gap-3 px-2 py-2 border-b border-gray-300"
              >
                <div className={`w-2 h-2 rounded-full bg-${i.activity.category.color}-500`} />
                <div className="flex-grow truncate">
                  {i.activity.titleShort}
                </div>
                <button
                  type="button"
                  onClick={() => handleUpdateTagActivity(i, true)}
                  className="flex self-center w-32 p-1 border border-gray-600 rounded-lg shadow-md"
                >
                  <PlayIcon className="w-6 h-6 mr-2 text-blue-500" />
                  {formatNumber(i.start)}
                </button>
                <button
                  type="button"
                  onClick={() => handleUpdateTagActivity(i, false)}
                  className="flex self-center w-32 p-1 border border-gray-600 rounded-lg shadow-md"
                >
                  <StopIcon className="w-6 h-6 mr-2 text-blue-500" />
                  {formatNumber(i.end)}
                </button>
                <button
                  type="button"
                  onClick={() => handleDeleteTagActivity(i.id)}
                  className="self-center p-1 border border-gray-500 rounded-full"
                >
                  <div className="w-6 h-6">
                    <TrashIcon className="w-6 h-6 text-red-500 " />
                  </div>
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* end table */}
    </DefaultLayout>
  )
}

export default PomeLog
