import React from 'react'
import { API } from 'aws-amplify'
import { updateCourse } from 'graphql/mutationsCustom'
import PropTypes from 'prop-types'
import { TextInput } from '../inputs/text-input'

const UpdateCourse = async (data) => {
  const mutation = await API.graphql(
    {
      query: updateCourse,
      authMode: 'AMAZON_COGNITO_USER_POOLS',
      variables: {
        input: data,
      },
    },
  )
  return mutation
}

const FormUpdateCourse = ({ close, course }) => {
  const [inputs, setInputs] = React.useState({
    title: '',
    description: '',
  })

  const handleChange = (event) => {
    const { name, value } = event.target
    setInputs((prev) => ({ ...prev, [name]: value }))
  }
  const handleClose = () => {
    setInputs({
      title: '',
      description: '',
    })
    close()
  }
  const handleSubmit = async (event) => {
    event.preventDefault()

    await UpdateCourse({
      ...course,
      title: inputs.title || course.title,
      description: inputs.description || course.description,
    })
    setInputs({
      title: '',
      description: '',
    })
    close()
  }

  return (

    <form onSubmit={handleSubmit}>
      <div className="flex flex-col justify-between w-full p-5 mx-auto min-h-96">
        <div>
          <h3 className="text-2xl font-semibold leading-loose tracking-wide text-left text-gray-700 uppercase border-b-2 border-gray-300">update course</h3>
          <TextInput name="title" label="title" value={inputs.title || course.title} handleChange={handleChange} />
          <TextInput name="description" label="description" value={inputs.description || course.description} handleChange={handleChange} />

          <div className="flex flex-row justify-around mt-5">
            <input type="submit" className="w-1/3 h-10 px-3 text-gray-100 capitalize bg-blue-500 rounded-md cursor-pointer" value="Update" />
            <button onClick={handleClose} type="button" className="w-1/3 h-10 px-3 text-gray-900 capitalize border border-gray-400 rounded-md "> Close </button>
          </div>
        </div>
      </div>
    </form>
  )
}

FormUpdateCourse.propTypes = {
  close: PropTypes.func.isRequired,
  course: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
}

export default FormUpdateCourse

export { FormUpdateCourse }
