import React from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { DefaultLayout, Modal } from 'components'
import { getModel } from 'graphql/queriesCustom'
import { useParams, Link } from 'react-router-dom'
import { FormCreateCategory } from 'components/forms/category/createCategory'
import { FormDeleteCategory } from 'components/forms/category/deleteCategory'
import { FormUpdateCategory } from 'components/forms/category/updateCategory'
import { FormCreateActivity } from 'components/forms/activity/createActivity'
import { FormUpdateActivity } from 'components/forms/activity/updateActivity'
import { FormDeleteActivity } from 'components/forms/activity/deleteActivity'
import { UploadModelFromFile } from 'components/forms/uploadModelFromFile'
import { TreDotsModal } from 'components/modals/treDotsModal'
import {
  onCreateCategory, onDeleteCategory,
  onUpdateCategory, onCreateActivity,
  onUpdateActivity, onDeleteActivity,
} from 'graphql/subscriptionsCustom'
import { Add } from 'components/icons/add'
import { IfIsAdmin } from 'components/conditionaly-render/if-admin'

const tryUnsubscribe = (subscription) => () => {
  try {
    return subscription.unsubscribe()
  } catch (error) {
    return ''
  }
}
const Model = () => {
  const { id } = useParams()
  const [model, setModel] = React.useState({})

  const [show, toggle] = React.useState(false)
  const [showFile, toggleFile] = React.useState(false)
  const [showActivity, toggleActivity] = React.useState(false)
  const [showUpdate, toggleUpdate] = React.useState(false)
  const [showUpdateActivity, toggleUpdateActivity] = React.useState(false)
  const [showDelete, toggleDelete] = React.useState(false)
  const [showDeleteActivity, toggleDeleteActivity] = React.useState(false)
  const [selectedCategory, setSelectedCategory] = React.useState({})
  const [selectedActivity, setSelectedActivity] = React.useState({})
  const [categories, setCategories] = React.useState([])
  const [filter, setFilter] = React.useState('')
  const [suggestedPriority, setSuggestedPriority] = React.useState({})
  const [filteredCategories, setFilteredCategories] = React.useState([])
  const handleChange = (event) => {
    const { value } = event.target
    setFilter(value)
  }
  const getCurrentModel = React.useCallback(async () => {
    const { data } = await API.graphql(
      graphqlOperation(getModel, { id }),
    )
    setModel(data.getModel)
  }, [id])
  React.useEffect(() => {
    const mappedCategories = categories
      .map((cat) => [cat.id, cat.activities?.items.map((item) => item.priority)])
    setSuggestedPriority(
      Object.fromEntries(
        mappedCategories.map((categoryItem) => [categoryItem[0],
          1 + categoryItem[1].reduce((prev, current) => (current > prev ? current : prev), 0),
        ]),
      ),
    )
  }, [categories])
  React.useEffect(() => {
    const subscription = API.graphql(
      graphqlOperation(onDeleteCategory),
    ).subscribe({
      next: (categoryData) => setCategories((prev) => {
        const execution = categoryData.value.data.onDeleteCategory
        return prev.filter(({ id: pId }) => execution.id !== pId)
      }),
    })
    return tryUnsubscribe(subscription)
  }, [])
  React.useEffect(() => {
    setCategories(model?.categories?.items || [])
  }, [model])
  React.useEffect(() => {
    const subscription = API.graphql(
      graphqlOperation(onCreateCategory),
    ).subscribe({
      next: (categoryData) => setCategories(
        (prev) => {
          if (categoryData.value.data.onCreateCategory.modelID === id) {
            return [...prev, categoryData.value.data.onCreateCategory]
          }
          return prev
        },
      ),
    })
    return tryUnsubscribe(subscription)
  }, [id])
  React.useEffect(() => {
    const subscription = API.graphql(
      graphqlOperation(onCreateActivity),
    ).subscribe({
      next: (activityData) => setCategories((prev) => {
        const activity = activityData.value.data.onCreateActivity
        for (let index = 0; index < prev.length; index += 1) {
          const element = prev[index]
          if (element.id === activity.categoryID) {
            const copy = [...prev]
            copy[index] = {
              ...copy[index],
              activities: {
                ...copy[index].activities,
                items: [...copy[index].activities.items, activity],
              },
            }
            return copy
          }
        }
        return prev
      }),
    })
    return tryUnsubscribe(subscription)
  }, [])
  React.useEffect(() => {
    const subscription = API.graphql(
      graphqlOperation(onUpdateCategory),
    ).subscribe({
      next: (categoryData) => setCategories((prev) => {
        const category = categoryData.value.data.onUpdateCategory
        for (let index = 0; index < prev.length; index += 1) {
          const element = prev[index]
          if (element.id === category.id) {
            const copy = [...prev]
            copy[index] = { ...copy[index], ...category }
            return copy
          }
        }
        return prev
      }),
    })
    return tryUnsubscribe(subscription)
  }, [])

  React.useEffect(() => {
    const subscription = API.graphql(
      graphqlOperation(onUpdateActivity),
    ).subscribe({
      next: (activityData) => setCategories((prev) => {
        const activity = activityData.value.data.onUpdateActivity
        for (let index = 0; index < prev.length; index += 1) {
          const element = prev[index]
          if (element.id === activity.categoryID) {
            const copy = [...prev]
            copy[index] = {
              ...copy[index],
              activities: {
                ...copy[index].activities,
                items: copy[index].activities.items.map(
                  (i) => (i.id === activity.id ? activity : i),
                ),
              },
            }
            return copy
          }
        }
        return prev
      }),
    })
    return tryUnsubscribe(subscription)
  }, [])
  React.useEffect(() => {
    const subscription = API.graphql(
      graphqlOperation(onDeleteActivity),
    ).subscribe({
      next: (activityData) => setCategories((prev) => {
        const activity = activityData.value.data.onDeleteActivity
        for (let index = 0; index < prev.length; index += 1) {
          const element = prev[index]
          if (element.id === activity?.categoryID) {
            const copy = [...prev]
            copy[index] = {
              ...copy[index],
              activities: {
                ...copy[index].activities,
                items: copy[index].activities.items.filter((i) => (i.id !== activity.id)),
              },
            }
            return copy
          }
        }
        return prev
      }),
    })
    return tryUnsubscribe(subscription)
  }, [])
  React.useEffect(() => {
    getCurrentModel()
  }, [getCurrentModel])
  React.useEffect(() => {
    setFilteredCategories(
      categories.sort((a, b) => a.priority - b.priority)
        .map((cat) => ({
          ...cat,
          activities: { items: cat.activities?.items.sort((a, b) => a.priority - b.priority) },
        }))
        .filter(
          (cat) => cat.title.toLowerCase().search(filter.toLowerCase()) !== -1
        || cat.titleShort.toLowerCase().search(filter.toLowerCase()) !== -1,
        ),
    )
  }, [categories, filter])

  return (
    <DefaultLayout>
      <Modal show={showFile && categories.length === 0}>
        <UploadModelFromFile close={() => toggleFile(false)} modelID={id} />
      </Modal>
      <Modal show={show}>
        <FormCreateCategory close={() => toggle(false)} modelID={id} />
      </Modal>
      <Modal show={showActivity}>
        <div>
          {
          selectedCategory.id && showActivity && (
            <FormCreateActivity
              priority={suggestedPriority[selectedCategory.id] || 0}
              close={() => toggleActivity(false)}
              categoryID={selectedCategory.id}
            />
          )
        }
        </div>
      </Modal>
      <Modal show={showUpdate}>
        <div>
          {selectedCategory.id && showUpdate
          && (
          <FormUpdateCategory
            category={{ ...selectedCategory }}
            close={() => { toggleUpdate(false) }}
          />
          )}
        </div>
      </Modal>
      <Modal show={showUpdateActivity}>
        <div>
          {selectedActivity.id && showUpdateActivity
          && (
          <FormUpdateActivity
            activity={{ ...selectedActivity }}
            close={() => { toggleUpdateActivity(false) }}
          />
          )}
        </div>
      </Modal>
      <Modal show={showDelete}>
        <div>
          {selectedCategory.id && showDelete
          && (
          <FormDeleteCategory
            category={{ ...selectedCategory }}
            close={() => { toggleDelete(false) }}
          />
          )}
        </div>
      </Modal>
      <Modal show={showDeleteActivity}>
        <div>
          {selectedActivity.id && showDeleteActivity
          && (
          <FormDeleteActivity
            activity={{ ...selectedActivity }}
            close={() => { toggleDeleteActivity(false) }}
          />
          )}
        </div>
      </Modal>
      <h1 className="text-xl tracking-widest capitalize">
        <Link to="/models" className="hover:text-blue-600 hover:underline">
          My Models
        </Link>
        {' > '}
        {model?.title || 'Loading' }
      </h1>
      <div className="flex flex-row items-baseline justify-between mt-6">
        <IfIsAdmin>
          <button onClick={() => toggle(true)} type="button" className="w-2/12 h-10 px-3 text-gray-100 capitalize bg-blue-500 rounded-md ">
            + New category
          </button>
          {categories.length === 0 ? (
            <button onClick={() => toggleFile(true)} type="button" className="w-2/12 h-10 px-3 text-gray-100 capitalize bg-blue-500 rounded-md ">
              Load from file
            </button>
          ) : null}
        </IfIsAdmin>
        <input value={filter} onChange={handleChange} type="search" name="courses" id="search" placeholder="Type To Search" className="w-4/12 h-10 px-5 border border-gray-400 rounded-md" />
      </div>
      {/* begin table */}
      <div className="mt-5">
        <table className="w-full table-auto">
          <thead className="font-normal border-b border-gray-300">
            <tr className="text-left">
              <th className="px-4 py-2 font-semibold capitalize">Category</th>
              <th className="px-4 py-2 font-semibold capitalize">Activity</th>
              <th className="px-4 py-2 font-semibold capitalize">Category Order</th>
              <th className="px-4 py-2 font-semibold capitalize">Activity Order</th>
              <th className="px-4 py-2 font-semibold capitalize"> </th>
            </tr>
          </thead>
          <tbody>
            {filteredCategories.map(({
              title, titleShort, id: categoryID, priority, color, activities,
            }) => (
              <React.Fragment key={categoryID}>
                <tr key={`cat${categoryID}`} className={`hover:bg-gray-100 transition-colors duration-500 border-l-8 border-${color}-500`}>
                  <td className="px-4 py-2">
                    <div className="text-lg font-light capitalize">
                      {title}
                    </div>
                    <div className="text-sm font-light text-gray-600 capitalize">
                      {titleShort}
                    </div>
                  </td>
                  <td className="px-4 py-2" />
                  <td className="px-4 py-2">{priority}</td>
                  <td className="px-4 py-2" />
                  <td className="relative">
                    <div className="flex flex-row justify-end">
                      <IfIsAdmin>
                        <button
                          type="button"
                          className="w-10 h-10"
                          onClick={() => {
                            setSelectedCategory({
                              id: categoryID, priority, color, title, titleShort,
                            })
                            toggleActivity(true)
                          }}
                        >
                          <Add />
                        </button>
                      </IfIsAdmin>
                      <TreDotsModal
                        onAddActivity={() => {
                          setSelectedCategory({
                            id: categoryID, priority, color, title, titleShort,
                          })
                          toggleActivity(true)
                        }}
                        onEdit={() => {
                          setSelectedCategory({
                            id: categoryID, priority, color, title, titleShort,
                          })
                          toggleUpdate(true)
                        }}
                        onDelete={() => {
                          setSelectedCategory({
                            id: categoryID,
                          })
                          toggleDelete(true)
                        }}
                      />
                    </div>
                  </td>
                </tr>
                {activities.items.map((act) => (
                  <tr key={`a${act.id}`} className={`hover:bg-gray-100 transition-colors duration-500 border-l-4 border-${color}-500`}>
                    <td className="px-4 py-2" />
                    <td className="px-4 py-2">
                      <div className="text-lg font-light capitalize">
                        {act.titleShort}
                      </div>
                      <div className="text-sm font-light text-gray-600 capitalize">
                        {act.title}
                      </div>
                    </td>
                    <td className="px-4 py-2" />
                    <td className="px-4 py-2">{act.priority}</td>
                    <td className="flex justify-end">
                      <TreDotsModal
                        onEdit={() => {
                          setSelectedActivity({
                            id: act.id,
                            priority: act.priority,
                            title: act.title,
                            titleShort: act.titleShort,
                          })
                          toggleUpdateActivity(true)
                        }}
                        onDelete={() => {
                          setSelectedActivity({
                            id: act.id,
                          })
                          toggleDeleteActivity(true)
                        }}
                      />
                    </td>
                  </tr>
                ))}
                <tr />
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
      {/* end table */}
    </DefaultLayout>
  )
}

export default Model
