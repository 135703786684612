import React from 'react'
import { API } from 'aws-amplify'
import { deleteActivity } from 'graphql/mutationsCustom'
import PropTypes from 'prop-types'

const DeleteActivity = async (data) => API.graphql({
  query: deleteActivity,
  authMode: 'AMAZON_COGNITO_USER_POOLS',
  variables: {
    input: data,
  },
})

const FormDeleteActivity = ({ close, activity }) => {
  const handleSubmit = async (event) => {
    event.preventDefault()
    await DeleteActivity({ ...activity })
    close()
  }
  return (

    <div className=" mx-auto w-full h-96 p-5">
      <h3 className="text-2xl text-gray-700 uppercase leading-loose tracking-wide text-center font-semibold">are you sure?</h3>
      <form onSubmit={handleSubmit}>
        <div className="flex flex-row justify-around mt-5">
          <button onClick={close} type="button" className="w-1/3  capitalize px-3 bg-blue-500 text-gray-100 h-10 rounded-md "> Close </button>
          <input type="submit" className="w-1/3 capitalize px-3 bg-blue-500 text-gray-100 h-10 rounded-md cursor-pointer" value="Delete" />
        </div>

      </form>
    </div>
  )
}

FormDeleteActivity.propTypes = {
  close: PropTypes.func.isRequired,
  activity: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
}

export default FormDeleteActivity

export { FormDeleteActivity }
