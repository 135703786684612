/* eslint-disable */
export const createActivity = /* GraphQL */ `
  mutation CreateActivity(
    $input: CreateActivityInput!
    $condition: ModelActivityConditionInput
  ) {
    createActivity(input: $input, condition: $condition) {
      id
      title
      titleShort
      priority
      categoryID
      createdAt
      updatedAt
    }
  }
`;
export const updateActivity = /* GraphQL */ `
  mutation UpdateActivity(
    $input: UpdateActivityInput!
    $condition: ModelActivityConditionInput
  ) {
    updateActivity(input: $input, condition: $condition) {
      id
      title
      titleShort
      priority
      categoryID
      createdAt
      updatedAt
    }
  }
`;

export const deleteActivity = /* GraphQL */ `
  mutation DeleteActivity(
    $input: DeleteActivityInput!
    $condition: ModelActivityConditionInput
  ) {
    deleteActivity(input: $input, condition: $condition) {
      id
      title
      titleShort
      priority
      categoryID
      createdAt
      updatedAt
    }
  }
`;

export const createCategory = /* GraphQL */ `
  mutation CreateCategory(
    $input: CreateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    createCategory(input: $input, condition: $condition) {
      id
      title
      titleShort
      priority
      color
      modelID
      activities {
        items {
          id
          title
          titleShort
          priority
          categoryID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;


export const deleteCategory = /* GraphQL */ `
  mutation DeleteCategory(
    $input: DeleteCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    deleteCategory(input: $input, condition: $condition) {
      id
      title
      titleShort
      priority
      color
      modelID
      activities {
        items {
          id
          title
          titleShort
          priority
          categoryID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;


export const updateCategory = /* GraphQL */ `
  mutation UpdateCategory(
    $input: UpdateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    updateCategory(input: $input, condition: $condition) {
      id
      title
      titleShort
      priority
      color
      modelID
      activities {
        items {
          id
          title
          titleShort
          priority
          categoryID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

export const createModel = /* GraphQL */ `
  mutation CreateModel(
    $input: CreateModelInput!
    $condition: ModelModelConditionInput
  ) {
    createModel(input: $input, condition: $condition) {
      id
      title
      description
      createdAt
      updatedAt
    }
  }
`;

export const updateModel = /* GraphQL */ `
  mutation UpdateModel(
    $input: UpdateModelInput!
    $condition: ModelModelConditionInput
  ) {
    updateModel(input: $input, condition: $condition) {
      id
      title
      description
      createdAt
      updatedAt
    }
  }
`;

export const deleteModel = /* GraphQL */ `
  mutation DeleteModel(
    $input: DeleteModelInput!
    $condition: ModelModelConditionInput
  ) {
    deleteModel(input: $input, condition: $condition) {
      id
      title
      description
      createdAt
      updatedAt
    }
  }
`;



export const createCourse = /* GraphQL */ `
  mutation CreateCourse(
    $input: CreateCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    createCourse(input: $input, condition: $condition) {
      id
      title
      students
      description
      owner
      modelID
      createdAt
      updatedAt
    }
  }
`;
export const updateCourse = /* GraphQL */ `
  mutation UpdateCourse(
    $input: UpdateCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    updateCourse(input: $input, condition: $condition) {
      id
      title
      description
      owner
      modelID
      students
      createdAt
      updatedAt
    }
  }
`;
export const deleteCourse = /* GraphQL */ `
  mutation DeleteCourse(
    $input: DeleteCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    deleteCourse(input: $input, condition: $condition) {
      id
      title
      description
      owner
      modelID
      createdAt
      updatedAt
    }
  }
`;


export const createTag = /* GraphQL */ `
  mutation CreateTag(
    $input: CreateTagInput!
    $condition: ModelTagConditionInput
  ) {
    createTag(input: $input, condition: $condition) {
      id
      title
      description
      executionID
      owner
      createdAt
      updatedAt
    }
  }
`;

export const updateTag = /* GraphQL */ `
  mutation UpdateTag(
    $input: UpdateTagInput!
    $condition: ModelTagConditionInput
  ) {
    updateTag(input: $input, condition: $condition) {
      id
      title
      description
      executionID
      owner
      createdAt
      updatedAt
    }
  }
`;

export const deleteTag = /* GraphQL */ `
  mutation DeleteTag(
    $input: DeleteTagInput!
    $condition: ModelTagConditionInput
  ) {
    deleteTag(input: $input, condition: $condition) {
      id
      title
      description
      executionID
      owner
      createdAt
      updatedAt
    }
  }
`;
export const createExecution = /* GraphQL */ `
  mutation CreateExecution(
    $input: CreateExecutionInput!
    $condition: ModelExecutionConditionInput
  ) {
    createExecution(input: $input, condition: $condition) {
      id
      title
      executor
      description
      courseID
      identityID
      students
      createdAt
      updatedAt
    }
  }
`;
export const updateExecution = /* GraphQL */ `
  mutation UpdateExecution(
    $input: UpdateExecutionInput!
    $condition: ModelExecutionConditionInput
  ) {
    updateExecution(input: $input, condition: $condition) {
      id
      title
      executor
      description
      identityID
      courseID
      students
      createdAt
      updatedAt
    }
  }
`;
export const deleteExecution = /* GraphQL */ `
  mutation DeleteExecution(
    $input: DeleteExecutionInput!
    $condition: ModelExecutionConditionInput
  ) {
    deleteExecution(input: $input, condition: $condition) {
      id
      title
      executor
      description
      courseID
      students
      createdAt
      updatedAt
    }
  }
`;

export const createTagActivity = /* GraphQL */ `
  mutation CreateTagActivity(
    $input: CreateTagActivityInput!
    $condition: ModelTagActivityConditionInput
  ) {
    createTagActivity(input: $input, condition: $condition) {
      id
      activityID
      tagID
      start
      end
      createdAt
      updatedAt
    }
  }
`;


export const deleteTagActivity = /* GraphQL */ `
  mutation DeleteTagActivity(
    $input: DeleteTagActivityInput!
    $condition: ModelTagActivityConditionInput
  ) {
    deleteTagActivity(input: $input, condition: $condition) {
      id
      activityID
      tagID
      start
      end
      createdAt
      updatedAt
    }
  }
`;

export const updateTagActivity = /* GraphQL */ `
  mutation UpdateTagActivity(
    $input: UpdateTagActivityInput!
    $condition: ModelTagActivityConditionInput
  ) {
    updateTagActivity(input: $input, condition: $condition) {
      id
      activityID
      tagID
      start
      end
      activity {
        id
        title
        titleShort
        priority
        categoryID
        category {
          color
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;