import React, { useEffect } from 'react'
import { API } from 'aws-amplify'
import { createCategory, createActivity } from 'graphql/mutationsCustom'
import PropTypes from 'prop-types'

const CreateActivity = async ({
  title,
  titleShort,
  priority,
  categoryID,
}) => API.graphql({
  query: createActivity,
  authMode: 'AMAZON_COGNITO_USER_POOLS',
  variables: {
    input: {
      title,
      titleShort,
      priority,
      categoryID,
    },
  },

})
const colors = ['gray',
  'red',
  'orange',
  'yellow',
  'green',
  'teal',
  'blue',
  'indigo',
  'purple',
  'pink']

const CreateCategory = async ({
  title, titleShort, color, modelID, priority,
}) => {
  const mutation = await API.graphql(
    {
      query: createCategory,
      authMode: 'AMAZON_COGNITO_USER_POOLS',
      variables: {
        input: {
          title, titleShort, color, priority, modelID,
        },
      },
    },
  )
  return mutation
}

const UploadModelFromFile = ({ close, modelID }) => {
  const [prevFile, setPrevFile] = React.useState(null)
  const [file, setFile] = React.useState(null)
  const [categories, setCategories] = React.useState([])
  const [categoriesShort, setCategoriesShort] = React.useState([])
  const [activities, setActivities] = React.useState([])
  const [categoryNameToId, setCategoryNameToId] = React.useState(null)

  const onChange = (e) => {
    setPrevFile(e.target.files[0])
  }
  useEffect(() => {
    if (!file) return
    const reader = new FileReader()
    reader.onload = (event) => {
      const text = event.target.result
      const lines = text.split('\n')
        .slice(1)
        .map((i) => i.split(','))
      const cat = new Set(lines.map((i) => i[0]))
      setCategories([...cat])
      setCategoriesShort([...new Set(lines.map((i) => i[1]))])
      setActivities(lines.map((i) => ({
        title: i[2],
        titleShort: i[3],
        categoryTitle: i[0],
      })))
    }
    reader.readAsText(file)
  }, [file])

  useEffect(() => {
    if (!categories || categories.length === 0) return
    if (!categoriesShort || categoriesShort.length === 0) return
    if (!modelID) return
    const create = async () => {
      const createdCategories = await Promise.all(categories.map(async (title, priority) => {
        const { data: { createCategory: createdCat } } = await CreateCategory({
          title,
          titleShort: categoriesShort[priority],
          color: colors[priority],
          priority,
          modelID,
        })
        return createdCat
      }))
      setCategoryNameToId(
        createdCategories.reduce((p, c) => ({ ...p, [c.title]: c.id }), {}),
      )
    }
    create()
  }, [categories, categoriesShort, modelID])
  useEffect(() => {
    if (!categoryNameToId || activities.length === 0) return
    activities.forEach(({ title, titleShort, categoryTitle }, priority) => {
      CreateActivity({
        title,
        titleShort,
        categoryID: categoryNameToId[categoryTitle],
        priority,
      })
    })
  }, [categoryNameToId, activities])
  const handleSubmit = async (event) => {
    event.preventDefault()
    setFile(prevFile)
    close()
  }
  return (

    <div className=" mx-auto w-full h-96 p-5">
      <h3 className="text-2xl text-gray-700 uppercase leading-loose tracking-wide text-center font-semibold">Upload File</h3>
      <form onSubmit={handleSubmit}>
        <label
          htmlFor="fileinput"
          className="flex flex-row justify-between mt-5"
        >
          <div className="capitalize inline-block">
            Model
          </div>
          <div className="inline-block w-7/12">
            <div className="inline-block border rounded-md  border-gray-400 px-5 h-10 w-full items-baseline align-middle text-gray-500">
              <div className="flex justify-center h-full flex-col">
                {prevFile ? `uploaded file: ${prevFile.name}` : 'Upload Model from csv file'}
              </div>
            </div>
          </div>
          <input
            type="file"
            accept="text/csv"
            onChange={(evt) => onChange(evt)}
            className="hidden z-30"
            id="fileinput"
          />
        </label>

        <div className="flex flex-row justify-around mt-5">
          <button onClick={close} type="button" className="w-1/3  capitalize px-3 bg-blue-500 text-gray-100 h-10 rounded-md "> Close </button>
          <input type="submit" className="w-1/3 capitalize px-3 bg-blue-500 text-gray-100 h-10 rounded-md cursor-pointer" value="Create" />
        </div>
      </form>
    </div>
  )
}

UploadModelFromFile.propTypes = {
  close: PropTypes.func.isRequired,
  modelID: PropTypes.string.isRequired,
}

export default UploadModelFromFile

export { UploadModelFromFile }
