import React, { useContext } from 'react'
import { API, Auth } from 'aws-amplify'
import { updateExecution } from 'graphql/mutationsCustom'
import PropTypes from 'prop-types'
import { VideoContext } from 'store/videoContext'
import { TextInput } from '../inputs/text-input'

const UpdateExecution = async (data) => {
  const mutation = await API.graphql(
    {
      query: updateExecution,
      authMode: 'AMAZON_COGNITO_USER_POOLS',
      variables: {
        input: { ...data, identityID: (await Auth.currentUserCredentials()).identityId },
      },
    },
  )
  return mutation
}

const FormUpdateExecution = ({ close, execution }) => {
  const videoContext = useContext(VideoContext)
  const [file, setFile] = React.useState(null)
  const [inputs, setInputs] = React.useState({
    title: execution.title || '',
    description: execution.description || '',
    executor: execution.executor || '',
  })
  const onChange = (e) => {
    setFile(e.target.files[0])
  }
  const handleClose = () => {
    setInputs({
      title: execution.title || '',
      description: execution.description || '',
      executor: execution.executor || '',
    })
    close()
  }
  const handleChange = (event) => {
    const { name, value } = event.target
    setInputs((prev) => ({ ...prev, [name]: value }))
  }
  const handleSubmit = async (event) => {
    event.preventDefault()
    const result = await UpdateExecution({
      ...execution,
      title: inputs.title || execution.title,
      description: inputs.description || execution.description,
      executor: inputs.executor || execution.executor,
    })
    videoContext.setIsLoading(true)
    if (file) {
      Storage.put(result.data.createExecution.id, file, {
        contentType: 'video/mp4',
        level: 'protected',
        progressCallback(videoProgress) {
          videoContext.setLoading(videoProgress.loaded / videoProgress.total)
        },
      }).then(() => {
        videoContext.setLoading(0)
        videoContext.setIsLoading(false)
      }) // {key: "test.txt"}
    }
    close()
  }
  React.useEffect(() => {
    setInputs({
      title: execution.title || '',
      description: execution.description || '',
      executor: execution.executor || '',
    })
  }, [execution])
  return (

    <form onSubmit={handleSubmit}>
      <div className="flex flex-col justify-between w-full p-5 mx-auto h-96">
        <div>
          <h3 className="text-2xl font-semibold leading-loose tracking-wide text-left text-gray-700 uppercase border-b-2 border-gray-300">Edit Execution</h3>
          <TextInput value={inputs.title || execution.title} label="title" name="title" handleChange={handleChange} placeholder="Edit execution title" />
          <TextInput value={inputs.description || execution.description} label="description" name="description" handleChange={handleChange} placeholder="Edit execution description" />
          <TextInput value={inputs.executor || execution.executor} label="executor" name="executor" handleChange={handleChange} placeholder="Edit execution executor" />

          <label
            htmlFor="fileinput"
            className="flex flex-row justify-between mt-5"
          >
            <div className="inline-block capitalize">
              Video
            </div>
            <div className="inline-block w-7/12">
              <div className="items-baseline inline-block w-full h-10 px-5 text-gray-500 align-middle border border-gray-400 rounded-md">
                <div className="flex flex-col justify-center h-full">
                  {file ? `video: ${file.name}` : 'Upload Video'}
                </div>
              </div>
            </div>
            <input
              type="file"
              accept="video/mp4"
              onChange={(evt) => onChange(evt)}
              className="z-30 hidden"
              id="fileinput"
            />
          </label>
        </div>
        <div className="flex flex-row justify-around mt-5">
          <input disabled={videoContext.isLoading} type="submit" className="w-1/3 h-10 px-3 text-gray-100 capitalize bg-blue-500 rounded-md cursor-pointer" value="Update" />
          <button onClick={handleClose} type="button" className="w-1/3 h-10 px-3 text-gray-900 capitalize border border-gray-400 rounded-md "> Close </button>
        </div>
      </div>
    </form>
  )
}

FormUpdateExecution.propTypes = {
  close: PropTypes.func.isRequired,
  execution: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
    executor: PropTypes.string,
  }).isRequired,
}

export default FormUpdateExecution

export { FormUpdateExecution as UpdateExecution, UpdateExecution as UpdateExecutionFunction }
