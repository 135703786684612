import React from 'react'
import { API } from 'aws-amplify'
import { updateCategory } from 'graphql/mutationsCustom'
import PropTypes from 'prop-types'
import { TextInput } from '../inputs/text-input'

const UpdateCategory = async (data) => {
  const mutation = await API.graphql({
    query: updateCategory,
    authMode: 'AMAZON_COGNITO_USER_POOLS',
    variables: {
      input: data,
    },
  })
  return mutation
}

const FormUpdateCategory = ({ close, category }) => {
  const [inputs, setInputs] = React.useState({
    title: '',
    titleShort: '',
    color: '',
    priority: '',
  })
  const handleChange = (event) => {
    const { name, value } = event.target
    setInputs((prev) => ({ ...prev, [name]: value }))
  }
  const handleClose = () => {
    setInputs({
      title: '',
      titleShort: '',
      color: '',
      priority: '',
    })
    close()
  }
  const handleSubmit = async (event) => {
    event.preventDefault()
    await UpdateCategory({
      ...category,
      title: inputs.title || category.title,
      titleShort: inputs.titleShort || category.titleShort,
      color: inputs.color || category.color,
      priority: inputs.priority || category.priority,
    })
    close()
  }
  return (

    <form onSubmit={handleSubmit}>
      <div className="flex flex-col justify-between w-full p-5 mx-auto h-96">
        <div>
          <h3 className="text-2xl font-semibold leading-loose tracking-wide text-left text-gray-700 uppercase border-b-2 border-gray-300">update category</h3>
          <TextInput label="title" name="title" handleChange={handleChange} placeholder="insert category title" value={inputs.title || category.title} />
          <TextInput label="short title" name="titleShort" handleChange={handleChange} placeholder="insert short title" maxLength={35} value={inputs.titleShort || category.titleShort} />

          <label htmlFor="priority" className="flex flex-row justify-between mt-5">
            <div className="inline-block capitalize">
              Priority
            </div>
            <div className="inline-block w-7/12">
              <input value={inputs.priority || category.priority} name="priority" type="number" onChange={handleChange} className="inline-block w-full h-10 px-5 border border-gray-400 rounded-md" placeholder="priority" />
            </div>
          </label>
          <label htmlFor="title" className="flex flex-row justify-between mt-5">
            <div className="inline-block capitalize">
              color
            </div>
            <div className="inline-block w-7/12">
              <select value={inputs.color || category.color} name="color" id="color" onChange={handleChange} className="inline-block w-full h-10 px-5 border border-gray-400 rounded-md" placeholder="color">
                <option value={null}>Select color</option>
                <option value="gray">gray</option>
                <option value="red">red</option>
                <option value="orange">orange</option>
                <option value="yellow">yellow</option>
                <option value="green">green</option>
                <option value="teal">teal</option>
                <option value="blue">blue</option>
                <option value="indigo">indigo</option>
                <option value="purple">purple</option>
                <option value="pink">pink</option>
              </select>
            </div>
          </label>
        </div>
        <div className="flex flex-row justify-around mt-5">
          <button onClick={handleClose} type="button" className="w-1/3 h-10 px-3 text-gray-900 capitalize border border-gray-400 rounded-md "> Close </button>
          <input type="submit" className="w-1/3 h-10 px-3 text-gray-100 capitalize bg-blue-500 rounded-md cursor-pointer" value="Update" />
        </div>

      </div>
    </form>
  )
}
FormUpdateCategory.propTypes = {
  close: PropTypes.func.isRequired,
  category: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    titleShort: PropTypes.string,
    color: PropTypes.string,
    priority: PropTypes.number,
  }).isRequired,
}

export default FormUpdateCategory

export { FormUpdateCategory }
