import React from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { DefaultLayout, Modal } from 'components'
import { CreateCourse, UpdateCourse, DeleteCourse } from 'components/forms'
import { listCourses, listModels, getCourse } from 'graphql/queriesCustom'
import { onCreateCourse, onUpdateCourse, onDeleteCourse } from 'graphql/subscriptionsCustom'
import { Link } from 'react-router-dom'
import { TreDotsModal } from 'components/modals/treDotsModal'
import { IfIsAdmin } from 'components/conditionaly-render/if-admin'

const tryUnsubscribe = (subscription) => () => {
  try {
    return subscription.unsubscribe()
  } catch (error) {
    return ''
  }
}

const Home = () => {
  const [show, toggle] = React.useState(false)
  const [showUpdate, toggleUpdate] = React.useState(false)
  const [showDelete, toggleDelete] = React.useState(false)
  const [courses, setCourses] = React.useState([])
  const [models, setModels] = React.useState([])
  const [filteredCourses, setFilteredCourses] = React.useState([])
  const [filter, setFilter] = React.useState('')
  const [selectedCourse, setSelectedCourse] = React.useState({})
  const getCourses = React.useCallback(async () => {
    const { data } = await API.graphql(
      {
        query: listCourses,
      },
    )
    setCourses(data.listCourses?.items || [])
  }, [])
  const getModels = React.useCallback(async () => {
    const { data } = await API.graphql(
      {
        query: listModels,
      },
    )
    setModels(data.listModels?.items
      .reduce((prev, item) => ({ ...prev, [item.id]: { ...item } }), {}) || {})
  }, [])
  const handleChange = (event) => {
    const { value } = event.target
    setFilter(value)
  }

  React.useEffect(() => {
    const subscription = API.graphql(
      graphqlOperation(onCreateCourse),
    ).subscribe({
      next: (courseData) => setCourses((prev) => [...prev, courseData.value.data.onCreateCourse]),
    })
    return tryUnsubscribe(subscription)
  }, [])
  React.useEffect(() => {
    const subscription = API.graphql(
      graphqlOperation(onUpdateCourse),
    ).subscribe({
      next: async (courseData) => {
        const course = courseData.value.data.onUpdateCourse
        const { data } = await API.graphql(graphqlOperation(getCourse, course))
        setCourses((prev) => {
          for (let index = 0; index < prev.length; index += 1) {
            const element = prev[index]
            if (element.id === course.id) {
              const copy = [...prev]
              copy[index] = { ...copy[index], ...data.getCourse }
              return copy
            }
          }
          return prev
        })
      },
    })
    return tryUnsubscribe(subscription)
  }, [])
  React.useEffect(() => {
    const subscription = API.graphql(
      graphqlOperation(onDeleteCourse),
    ).subscribe({
      next: (courseData) => setCourses((prev) => {
        const course = courseData.value.data.onDeleteCourse
        return prev.filter(({ id }) => course.id !== id)
      }),
    })
    return tryUnsubscribe(subscription)
  }, [])

  React.useEffect(() => {
    setFilteredCourses(
      courses.filter((course) => course.title.toLowerCase().search(filter.toLowerCase()) !== -1
      || course.description.toLowerCase().search(filter.toLowerCase()) !== -1),
    )
  }, [courses, filter])
  React.useEffect(() => { getCourses(); getModels() }, [getCourses, getModels])
  return (
    <DefaultLayout>
      <Modal show={show}>
        <div>
          <CreateCourse close={() => { toggle(false) }} />
        </div>
      </Modal>
      <Modal show={showUpdate}>
        <div>
          {selectedCourse.id
          && <UpdateCourse course={{ ...selectedCourse }} close={() => { toggleUpdate(false) }} />}
        </div>
      </Modal>
      <Modal show={showDelete}>
        <div>
          {selectedCourse.id
          && <DeleteCourse course={{ ...selectedCourse }} close={() => { toggleDelete(false) }} />}
        </div>
      </Modal>
      <h1 className="text-xl tracking-widest capitalize">
        My Courses
      </h1>
      <div className="flex flex-row items-baseline justify-between mt-6">
        <IfIsAdmin>
          <button onClick={() => toggle(true)} type="button" className="w-2/12 h-10 px-3 text-gray-100 capitalize bg-blue-500 rounded-md ">
            + new course
          </button>
        </IfIsAdmin>
        <input value={filter} onChange={handleChange} type="search" name="courses" id="search" placeholder="Type To Search" className="w-4/12 h-10 px-5 border border-gray-400 rounded-md" />
      </div>
      {/* begin table */}
      <div className="mt-5">
        <table className="w-full table-auto">
          <thead className="font-normal border-b border-gray-300">
            <tr className="text-left">
              <th className="px-4 py-2 font-semibold capitalize">Title</th>
              <th className="px-4 py-2 font-semibold capitalize">Model</th>
              <th className="px-4 py-2 font-semibold capitalize">Created</th>
              <th className="px-4 py-2 font-semibold capitalize"> </th>
            </tr>
          </thead>
          <tbody>
            {filteredCourses.map(({
              title, createdAt, description, id, modelID, students,
            }) => (
              <tr className="transition-colors duration-500 hover:bg-gray-100 " key={id}>
                <td className="px-4 py-2">
                  <Link to={{ pathname: `/courses/${id}` }}>
                    <div className="text-lg font-light capitalize">
                      {title}
                    </div>
                  </Link>
                  <div className="text-xs font-light text-gray-600 capitalize">
                    {description}
                  </div>
                </td>
                <td className="px-4 py-2">{models[modelID]?.title}</td>
                <td className="px-4 py-2">{createdAt.split('T')[0]}</td>
                <td className="relative">
                  <TreDotsModal
                    onEdit={() => setSelectedCourse({
                      title, description, id, students,
                    }, toggleUpdate(true))}
                    onDelete={() => setSelectedCourse({ id }, toggleDelete(true))}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* end table */}
    </DefaultLayout>
  )
}

export default Home
