import React from 'react'
import { API } from 'aws-amplify'
import { updateActivity } from 'graphql/mutationsCustom'
import PropTypes from 'prop-types'
import { TextInput } from '../inputs/text-input'

const UpdateActivity = async (data) => {
  const mutation = await API.graphql({
    query: updateActivity,
    authMode: 'AMAZON_COGNITO_USER_POOLS',
    variables: {
      input: data,
    },
  })
  return mutation
}

const FormUpdateActivity = ({ close, activity }) => {
  const [inputs, setInputs] = React.useState({
    title: '',
    titleShort: '',
    priority: '',
  })
  const handleChange = (event) => {
    const { name, value } = event.target
    setInputs((prev) => ({ ...prev, [name]: value }))
  }
  const handleClose = () => {
    setInputs({
      title: '',
      titleShort: '',
      priority: '',
    })
    close()
  }
  const handleSubmit = async (event) => {
    event.preventDefault()
    await UpdateActivity({
      ...activity,
      title: inputs.title || activity.title,
      titleShort: inputs.titleShort || activity.titleShort,
      priority: inputs.priority || activity.priority,
    })
    close()
  }
  return (

    <form onSubmit={handleSubmit}>
      <div className="flex flex-col justify-between w-full p-5 mx-auto h-96">
        <div>
          <h3 className="text-2xl font-semibold leading-loose tracking-wide text-left text-gray-700 uppercase border-b-2 border-gray-300">update activity</h3>
          <TextInput value={inputs.title || activity.title} label="title" name="title" handleChange={handleChange} placeholder="insert activity title" />
          <TextInput value={inputs.titleShort || activity.titleShort} maxLength={35} label="short title" name="titleShort" handleChange={handleChange} placeholder="insert activity short title" />
          <label htmlFor="priority" className="flex flex-row justify-between mt-5">
            <div className="inline-block capitalize">
              Priority
            </div>
            <div className="inline-block w-7/12">
              <input value={inputs.priority || activity.priority} name="priority" type="number" onChange={handleChange} className="inline-block w-full h-10 px-5 border border-gray-400 rounded-md" placeholder="priority" />
            </div>
          </label>
        </div>

        <div className="flex flex-row justify-around mt-5">
          <input type="submit" className="w-1/3 h-10 px-3 text-gray-100 capitalize bg-blue-500 rounded-md cursor-pointer" value="Update" />
          <button onClick={handleClose} type="button" className="w-1/3 h-10 px-3 text-gray-900 capitalize border border-gray-400 rounded-md "> Close </button>
        </div>

      </div>
    </form>
  )
}
FormUpdateActivity.propTypes = {
  close: PropTypes.func.isRequired,
  activity: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    titleShort: PropTypes.string,
    priority: PropTypes.number,
  }).isRequired,
}

export default FormUpdateActivity

export { FormUpdateActivity }
