import { Auth, API } from 'aws-amplify'

let nextToken

// eslint-disable-next-line import/prefer-default-export
export async function listUsers(limit) {
  const apiName = 'AdminQueries'
  const path = '/listUsers'
  const myInit = {
    queryStringParameters: {
      limit,
      token: nextToken,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  }
  const { NextToken, ...rest } = await API.get(apiName, path, myInit)
  nextToken = NextToken
  return rest
}
