import React from 'react'
import { API, button, graphqlOperation } from 'aws-amplify'
import { DefaultLayout, Modal } from 'components'
import { getCourse, getCourseLogs } from 'graphql/queriesCustom'
import { useParams, Link } from 'react-router-dom'
import { FormCreateExecution } from 'components/forms/execution/createExecution'
import { UpdateExecution } from 'components/forms/execution/updateExecution'
import { DeleteExecution } from 'components/forms/execution/deleteExecution'
import {
  onCreateExecution,
  onUpdateExecution,
  onDeleteExecution,
} from 'graphql/subscriptionsCustom'
import { TreDotsModal } from 'components/modals/treDotsModal'
// import load from '../../utils/save-data'
import { FormManageUsers } from 'components/forms/course/manage-users'
import { IfIsAdmin } from 'components/conditionaly-render/if-admin'

const tryUnsubscribe = (subscription) => () => {
  try {
    return subscription.unsubscribe()
  } catch (error) {
    return ''
  }
}

const executionsToCsv = (course) => {
  const executions = course.executions.items.map((item) => ({
    ...item,
    modelTitle: course.model.title,
    modelID: course.modelID,
    courseTitle: course.title,
  }))
  const tagEvents = executions
    .map((i) => i.tags.items.map((t) => ({
      ...i, ...t, executionTitle: i.title, executionID: i.id,
    })))
    .flat()
  const tags = tagEvents
    .map((i) => i.tags.items.map((t) => ({
      ...i,
      ...t,
      tagTitle: i.title,
      title: t.activity.title,
      titleShort: t.activity.titleShort,
      categoryTitle: t.activity.category.title,
      categoryTitleShort: t.activity.category.titleShort,
    })))
    .flat()
    .map((i) => [
      i.title,
      i.titleShort,
      i.categoryTitle,
      i.categoryTitleShort,
      i.tagTitle,
      i.executionTitle,
      i.courseTitle,
      i.start,
      i.end,
      i.id,
      i.tagID,
      i.activityID,
      i.courseID,
      i.executionID,
    ])
  return tags.map((i) => {
    const copy = { ...i }
    delete copy.tags
    delete copy.activity
    return copy
  })
}

const ExportData = () => {
  const { id } = useParams()

  const getCurrentCourse = React.useCallback(async () => {
    const { data } = await API.graphql(graphqlOperation(getCourseLogs, { id }))
    const tags = executionsToCsv(data.getCourse)
    const rows = [
      [
        'activity title',
        'activity title short',
        'category title',
        'category title short',
        'tag title',
        'execution title',
        'course title',
        'start (sec)',
        'end (sec)',
        'ID',
        'tagID',
        'activityID',
        'courseID',
        'executionID',
      ],
      ...tags,
    ]
    const csvContent = `data:text/csv;charset=utf-8,${rows
      .map((e) => Object.values(e).join(','))
      .join('\n')}`
    const encodedUri = encodeURI(csvContent)
    const link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute('download', `${data.getCourse.title}-logs.csv`)
    document.body.appendChild(link) // Required for FF
    link.click()
  }, [id])

  return (
    <button
      onClick={() => getCurrentCourse()}
      type="button"
      className="w-2/12 h-10 px-3 text-gray-100 capitalize bg-blue-500 rounded-md "
    >
      download logs
    </button>
  )
}

const Course = () => {
  const { id } = useParams()
  const [show, toggle] = React.useState(false)
  const [showUpdate, toggleUpdate] = React.useState(false)
  const [showDelete, toggleDelete] = React.useState(false)
  const [showManageUsers, toggleManageUsers] = React.useState(false)
  const [selectedExecution, setSelectedExecution] = React.useState({})
  const [course, setCourse] = React.useState({})
  const [executions, setExecutions] = React.useState([])
  const [filter, setFilter] = React.useState('')
  const [filteredExecutions, setFilteredExecutions] = React.useState([])
  const handleChange = (event) => {
    const { value } = event.target
    setFilter(value)
  }
  const getCurrentCourse = React.useCallback(async () => {
    const { data } = await API.graphql(graphqlOperation(getCourse, { id }))
    setCourse(data.getCourse)
  }, [id])
  React.useEffect(() => {
    setFilteredExecutions(
      executions.filter(
        (execution) => execution.title.toLowerCase().search(filter.toLowerCase()) !== -1
          || execution.description.toLowerCase().search(filter.toLowerCase())
            !== -1
          || execution.executor.toLowerCase().search(filter.toLowerCase()) !== -1,
      ),
    )
  }, [executions, filter])
  React.useEffect(() => {
    setExecutions(course?.executions?.items || []);
  }, [course])
  React.useEffect(() => {
    const subscription = API.graphql(
      graphqlOperation(onDeleteExecution),
    ).subscribe({
      next: (executionData) => setExecutions((prev) => {
        const execution = executionData.value.data.onDeleteExecution
        return prev.filter(({ id: pId }) => execution.id !== pId)
      }),
    })
    return tryUnsubscribe(subscription)
  }, [])
  React.useEffect(() => {
    getCurrentCourse()
  }, [getCurrentCourse])
  React.useEffect(() => {
    const subscription = API.graphql(
      graphqlOperation(onUpdateExecution),
    ).subscribe({
      next: (courseData) => setExecutions((prev) => {
        const execution = courseData.value.data.onUpdateExecution
        for (let index = 0; index < prev.length; index += 1) {
          const element = prev[index]
          if (element.id === execution.id) {
            const copy = [...prev]
            copy[index] = { ...copy[index], ...execution }
            return copy
          }
        }
        return prev
      }),
    })
    return tryUnsubscribe(subscription)
  }, [])
  React.useEffect(() => {
    const subscription = API.graphql(
      graphqlOperation(onCreateExecution),
    ).subscribe({
      next: (executionData) => setExecutions((prev) => {
        if (executionData.value.data.onCreateExecution.courseID === id) {
          return [...prev, executionData.value.data.onCreateExecution]
        }
        return prev
      }),
    })
    return tryUnsubscribe(subscription)
  }, [id])
  return (
    <DefaultLayout>
      <Modal show={showManageUsers}>
        <div>
          {course.id
          && (
          <FormManageUsers
            course={{ ...course }}
            close={() => { toggleManageUsers(false) }}
          />
          )}
        </div>
      </Modal>
      <Modal show={show}>
        <FormCreateExecution close={() => toggle(false)} courseID={id} />
      </Modal>
      <Modal show={showUpdate}>
        <div>
          {selectedExecution.id && (
            <UpdateExecution
              execution={{ ...selectedExecution }}
              close={() => {
                toggleUpdate(false)
              }}
            />
          )}
        </div>
      </Modal>
      <Modal show={showDelete}>
        <div>
          {selectedExecution.id && (
            <DeleteExecution
              execution={{ ...selectedExecution }}
              close={() => {
                toggleDelete(false)
              }}
            />
          )}
        </div>
      </Modal>
      <h1 className="text-xl tracking-widest capitalize">
        <Link to="/courses" className="hover:text-blue-600 hover:underline">
          My Courses
        </Link>
        {' > '}
        {course?.title || 'Loading'}
      </h1>
      <div className="flex flex-row justify-between gap-5 mt-6">
        <IfIsAdmin>
          <button
            onClick={() => toggle(true)}
            type="button"
            className="w-2/12 h-10 px-3 text-gray-100 capitalize bg-blue-500 rounded-md "
          >
            + New Execution
          </button>
          <button
            onClick={() => toggleManageUsers(true)}
            type="button"
            className="w-2/12 h-10 px-3 text-gray-100 capitalize bg-blue-500 rounded-md "
          >
            Manage Users
          </button>
        </IfIsAdmin>

        <Link
          to={`/courses/${course.id}/tag-analysis`}
          className="flex flex-col justify-center w-2/12 h-10 px-3 text-gray-100 capitalize bg-blue-500 rounded-md"
        >
          <div>
            Go to Tag Analysis
          </div>
        </Link>
        <ExportData />

        <input
          value={filter}
          onChange={handleChange}
          type="search"
          name="courses"
          id="search"
          placeholder="Type To Search"
          className="w-4/12 h-10 px-5 border border-gray-400 rounded-md"
        />
      </div>

      {/* begin table */}
      <div className="mt-5">
        <table className="w-full table-auto">
          <thead className="font-normal border-b border-gray-300">
            <tr className="text-left">
              <th className="px-4 py-2 font-semibold capitalize">title</th>
              <th className="px-4 py-2 font-semibold capitalize">executor</th>
              <th className="px-4 py-2 font-semibold capitalize">Created</th>
              <th className="px-4 py-2 font-semibold capitalize">Modified</th>
              <th className="px-4 py-2 font-semibold capitalize"> </th>
            </tr>
          </thead>
          <tbody>
            {filteredExecutions.map(
              ({
                title,
                executor,
                updatedAt,
                createdAt,
                description,
                id: executionId,
              }) => (
                <tr
                  className="transition-colors duration-500 hover:bg-gray-100 "
                  key={executionId}
                >
                  <td className="px-4 py-2">
                    <Link to={{ pathname: `/executions/${executionId}` }}>
                      <div className="text-lg font-light capitalize">
                        {title}
                      </div>
                    </Link>
                    <div className="text-sm font-light text-gray-600 capitalize">
                      {description}
                    </div>
                  </td>
                  <td className="px-4 py-2">{executor}</td>
                  <td className="px-4 py-2">{createdAt.split('T')[0]}</td>
                  <td className="px-4 py-2">{updatedAt.split('T')[0]}</td>
                  <td className="relative">
                    <TreDotsModal
                      onEdit={() => setSelectedExecution(
                        {
                          title,
                          description,
                          id: executionId,
                          executor,
                        },
                        toggleUpdate(true),
                      )}
                      onDelete={() => setSelectedExecution(
                        { id: executionId },
                        toggleDelete(true),
                      )}
                    />
                  </td>
                </tr>
              ),
            )}
          </tbody>
        </table>
      </div>
      {/* end table */}
    </DefaultLayout>
  )
}

export default Course
