import { useContext } from 'react';
import { UserContext } from 'store/user-context';

export const IfIsAdmin = ({ children }) => {
  const { currentUser } = useContext(UserContext);
  if (currentUser && currentUser.isAdmin) {
    return children;
  }
  return null;
}
export default IfIsAdmin
