import React from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { createCourse } from 'graphql/mutationsCustom'
import { listModels } from 'graphql/queriesCustom'
import PropTypes from 'prop-types'
import { TextInput } from '../inputs/text-input'

const CreateCourse = async ({
  title, description, modelID,
}) => {
  const mutation = await API.graphql(
    {
      query: createCourse,
      authMode: 'AMAZON_COGNITO_USER_POOLS',
      variables: {
        input: {
          title, description, modelID, students: [],
        },
      },
    },
  )
  return mutation
}

const FormCreateCourse = ({ close }) => {
  const [models, setModels] = React.useState([])
  const [selectedUsers, setSelectedUsers] = React.useState([])
  const [inputs, setInputs] = React.useState({
    title: '',
    description: '',
    modelID: '',
  })

  const getModels = React.useCallback(async () => {
    const { data } = await API.graphql(
      graphqlOperation(listModels),
    )
    setModels(data.listModels.items)
  }, [])

  const handleChange = (event) => {
    const { name, value } = event.target
    setInputs((prev) => ({ ...prev, [name]: value }))
  }
  const handleClose = () => {
    setSelectedUsers({})
    setInputs({
      title: '',
      description: '',
      modelID: '',
    })
    close()
  }
  const handleSubmit = async (event) => {
    event.preventDefault()
    await CreateCourse({
      ...inputs,
      students: Object.entries(selectedUsers).filter((entry) => entry[1]).map((entry) => entry[0]),
    })
    setSelectedUsers({})
    setInputs({
      title: '',
      description: '',
      modelID: '',
    })
    handleClose()
  }

  React.useEffect(() => {
    getModels()
  }, [getModels])
  return (

    <form onSubmit={handleSubmit}>
      <div className="flex flex-col justify-between w-full p-5 mx-auto min-h-96">
        <div>
          <h3 className="text-2xl font-semibold leading-loose tracking-wide text-left text-gray-700 uppercase border-b-2 border-gray-300">create new course</h3>
          <TextInput label="title" name="title" handleChange={handleChange} placeholder="insert course title" />
          <TextInput label="description" name="description" handleChange={handleChange} placeholder="insert course description" />
          <label htmlFor="title" className="flex flex-row justify-between mt-5">
            <div className="inline-block capitalize">
              Model
            </div>
            <div className="inline-block w-7/12">
              <select name="modelID" id="modelID" onChange={handleChange} className="inline-block w-full h-10 px-5 border border-gray-400 rounded-md" placeholder="modelID">
                <option value={null}>
                  Select Model
                </option>
                {models.map(({ id, title }) => (
                  <option key={id} value={id}>{title}</option>
                )) }
              </select>
            </div>
          </label>

        </div>
        <div className="flex flex-row justify-around mt-5">
          <input type="submit" className="w-1/3 h-10 px-3 text-gray-100 capitalize bg-blue-500 rounded-md cursor-pointer" value="Create" />
          <button onClick={handleClose} type="button" className="w-1/3 h-10 px-3 text-gray-900 capitalize border border-gray-400 rounded-md "> Close </button>
        </div>

      </div>
    </form>
  )
}

FormCreateCourse.propTypes = {
  close: PropTypes.func.isRequired,
}

export default FormCreateCourse

export { FormCreateCourse }
