import React from 'react'
import { API } from 'aws-amplify'
import { deleteModel } from 'graphql/mutationsCustom'
import PropTypes from 'prop-types'

const DeleteModel = async (data) => {
  const mutation = await API.graphql(
    {
      query: deleteModel,
      authMode: 'AMAZON_COGNITO_USER_POOLS',
      variables: {
        input: data,
      },
    },
  )
  return mutation
}

const FormDeleteModel = ({ close, model }) => {
  const handleSubmit = async (event) => {
    event.preventDefault()
    await DeleteModel({ ...model })
    close()
  }
  return (

    <div className=" mx-auto w-full h-96 p-5">
      <h3 className="text-2xl text-gray-700 uppercase leading-loose tracking-wide text-center font-semibold">are you sure?</h3>
      <form onSubmit={handleSubmit}>
        <div className="flex flex-row justify-around mt-5">
          <button onClick={close} type="button" className="w-1/3  capitalize px-3 bg-blue-500 text-gray-100 h-10 rounded-md "> Close </button>
          <input type="submit" className="w-1/3 capitalize px-3 bg-blue-500 text-gray-100 h-10 rounded-md cursor-pointer" value="Delete" />
        </div>

      </form>
    </div>
  )
}

FormDeleteModel.propTypes = {
  close: PropTypes.func.isRequired,
  model: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
}

export default FormDeleteModel

export { FormDeleteModel }
