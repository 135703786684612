import React, { createContext } from 'react'
import PropTypes from 'prop-types'

const VideoContext = createContext({})

const VideoStore = ({ children }) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [loading, setLoading] = React.useState(0)
  return (
    <VideoContext.Provider value={
    {
      isLoading,
      setIsLoading,
      loading,
      setLoading,
    }
  }
    >
      {children}
    </VideoContext.Provider>
  )
}
VideoStore.propTypes = { children: PropTypes.node.isRequired }

export { VideoContext, VideoStore }
